<div class="flex-display" [class.row-alignment]="alignment === 'row'" [class.column-alignment]="alignment === 'column'" [class.space-between]="position === 'space-between'" [class.center]="position === 'center'" [class.space-evenly]="position === 'space-evenly'">
	<p-dropdown #dropdownComponent
		*ngIf="!multiSelect"
		[style.width.%]="width"
		[options]="combinedOptions"
		[(ngModel)]="selectedValue"
		[optionLabel]="optionLabel"
		[optionValue]="optionValue"
		[virtualScroll]="virtualScroll"
		[itemSize]="itemSize"
		[dropdownIcon]="dropdownIcon"
		[showClear]="showClear"
		placeholder="{{ placeholder | translate }}"
		[filter]="true"
		[filterBy]="optionLabel"
		[disabled]="disabled"
		(onChange)="onValueChange($event)"
		(onFilter)="onValueFilter($event)"
		scrollHeight="{{ ((combinedOptions.length * 39 + 14) > 200 ? 200 : combinedOptions.length * 39 + 14) + 'px' }}"
		[class]="dropdownStyleClass ? dropdownStyleClass : null">
		<ng-template let-option pTemplate="item">
			<div>{{ option[optionLabel] | translate | translateCode }}</div>
		</ng-template>
	</p-dropdown>
	<p-multiSelect #multiSelectComponent
		*ngIf="multiSelect"
		[style.width.%]="width"
		[options]="combinedOptions"
		[(ngModel)]="selectedValue"
		[optionLabel]="optionLabel"
		[optionValue]="optionValue"
		[virtualScroll]="virtualScroll"
		[itemSize]="itemSize"
		[dropdownIcon]="dropdownIcon"
		[showClear]="showClear"
		placeholder="{{ placeholder | translate }}"
		[filter]="true"
		[filterBy]="optionLabel"
		[disabled]="disabled"
		(onChange)="onValueChange($event)"
		(onFilter)="onValueFilter($event)"
		scrollHeight="{{ ((combinedOptions.length * 39 + 14) > 200 ? 200 : combinedOptions.length * 39 + 14) + 'px' }}"
		[class]="dropdownStyleClass ? dropdownStyleClass : null">
		<ng-template let-option pTemplate="item">
			<div>{{ option[optionLabel] | translate | translateCode }}</div>
		</ng-template>
	</p-multiSelect>
	<ng-content></ng-content>
</div>
