// Modules
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'click-confirm',
	templateUrl: './click-confirm.component.html',
	styleUrls: ['./click-confirm.component.scss']
})
export class ClickConfirmComponent {
	message: string = 'SYSTEM.MESSAGE_CONFIRM';

	constructor(
		public dialogRef: MatDialogRef<ClickConfirmComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	onConfirm(): void {
		this.dialogRef.close(true);
	}
}
