// Modules
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// Services
import { TranslateService } from '@ngx-translate/core';
import { HttpHelperService } from 'src/app/shared/services/http-helper.service';
import { SystemCodeService } from 'src/app/shared/services/system-code.service';

@Component({
	selector: 'system-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	ngStorageUserName = window.localStorage.getItem('ngStorage-user_name');
	userName = this.ngStorageUserName ? this.ngStorageUserName.replace("\"", "").replace("\"", "") : window.localStorage.getItem('username');
	showZh: boolean;
	tasks: any[] = [];
	assignedTasksNumber: number = 0; // 已分配任务的数量
	pendingTasksNumber: number = 0; // 待定任务的数量
	showBroserSupportHint = false;
	// Determine operation system
	isXp = window.navigator.userAgent.indexOf('Windows NT 5.1') !== -1;
	isIE8 = !!window.navigator.userAgent.match(/MSIE 8.0/);
	isIE9 = !!window.navigator.userAgent.match(/MSIE 9.0/);
	isIE10 = !!window.navigator.userAgent.match(/MSIE 10.0/);
	isIE11 = window.navigator.userAgent.indexOf('Trident') > -1 && window.navigator.userAgent.indexOf("rv:11.0") > -1;
	isIEs = this.isXp || this.isIE8 || this.isIE9 || this.isIE10 || this.isIE11;
	constructor(
		private router: Router,
		private translateService: TranslateService,
		private http: HttpHelperService,
		private systemCodeService: SystemCodeService,
		) {
		this.showZh = translateService.currentLang === 'zh' ? false : true;
	}

	ngOnInit(): void {
		// this.systemCodeService.getInitCode([
		// 	CodeMapping.lifecycle, CodeMapping.task_status, CodeMapping.inspection_type_status]).then(res => {
		// 	});
		// 获取当前账号已分配任务
		// this.getTasks();
		// 获取当前账号待定任务
		// this.getPendingTasks();

		if (this.isXp && (this.isIE8 || this.isIE9 || this.isIE10)) {
			this.showBroserSupportHint = true;
		} else if (this.isIE8 || this.isIE9 || this.isIE10 || this.isIE11) {
			this.showBroserSupportHint = true;
		}
	}

	logOut(): void {
		window.localStorage.clear();
		this.router.navigate(['login']);
	}

	closeHint() {
		this.showBroserSupportHint = false;
	}

	// 切换语言
	changeLanguage(): void {
		this.showZh = !this.showZh;
		if (this.translateService.currentLang === 'zh') {
			this.translateService.use('en');
		} else {
			this.translateService.use('zh');
		}
	}

	// 已分配任务
	getTasks(): void {
		const query = {
			paging: {
				index: 0,
				size: 10000
			},
			filters: [
			{
				connection: 'AND',
				key: 'assignee',
				condition: '=',
				value: localStorage.getItem('ngStorage-user_id') ? localStorage.getItem('ngStorage-user_id') : localStorage.getItem('id')
			},
			],
			orders: [
			{
				key: 'created_dtm',
				desc: true
			}
			]
		};
		this.http.POST('taskInstance/list', query).subscribe(res => {
			if (res.status === 200) {
				this.assignedTasksNumber = res.data.length;
				this.tasks = [...this.tasks, ...res.data];
			}
		});
	}

	// 待定任务
	getPendingTasks(): void {
		const query = {
			paging: {
				index: 0,
				size: -1
			},
			filters: [
			{
				connection: 'AND',
				key: 'lifecycle',
				condition: '=',
				value: '_PSFO_LIFECYCLE_PENDING'
			}
			],
			orders: [
			{
				key: 'created_dtm',
				desc: true
			}
			]
		};
		this.http.POST('taskInstance/list', query).subscribe(res => {
			if (res.status === 200) {
				this.pendingTasksNumber = res.data.length;
				this.tasks = [...this.tasks, ...res.data];
			}
		});
	}

	// 接受任务 复审检查
	accept(task: any): void {
		task.task_detail.assignee = localStorage.getItem('ngStorage-user_id') ? localStorage.getItem('ngStorage-user_id') : localStorage.getItem('id');
		this.http.POST('instance?action=AR', task.task_detail).subscribe(res => {
			if (res.status === 200) {
				this.ngOnInit();
			}
		});
	}

	// 开始任务 复审检查
	start(task: any): void {
		// task.task_detail.status = '_PSFO_TASK_STATUS_INPROGRESS';
		this.http.POST(task.task_type === '_PSFO_TASK_TYPE_REVIEW_INSPECTION' ? 'instance?action=SR' : 'action', task.task_detail).subscribe(res => {
			if (res.status === 200) {
				this.navigateToPage(task);
			}
		});
	}

	// 接受并开始 复审检查
	acceptStart(task: any): void {
		task.task_detail.assignee = localStorage.getItem('ngStorage-user_id') ? localStorage.getItem('ngStorage-user_id') : localStorage.getItem('id');
		if (task.task_type !== '_PSFO_TASK_TYPE_REVIEW_INSPECTION') {
			task.task_detail.status = '_PSFO_TASK_STATUS_INPROGRESS';
		}
		task.task_detail.status = '_PSFO_TASK_STATUS_INPROGRESS';
		this.http.POST('instance?action=SR', task.task_detail).subscribe(res => {
			if (res.status === 200) {
				this.navigateToPage(task);
			}
		});
	}

	// 执行整改开始 接受执行整改
	acceptTask(task: any) {
		// task.task_detail.status = '_PSFO_TASK_STATUS_INPROGRESS';
		if (task.task_type == '_PSFO_TASK_TYPE_CONDUCT_ACTION') {
			this.http.POST('action?action=SA', task.task_detail).subscribe(res => {
				if (res.status === 200) {
					this.router.navigate(['rectification-task'], { queryParams: { id: task.task_detail.id } });
				}
			});
		} else if (task.task_type == '_PSFO_TASK_TYPE_REVIEW_INJECT') {
			this.http.POST('action?action=SX', task.task_detail).subscribe(res => {
				if (res.status === 200) {
					this.router.navigate(['rectification-task/review-task'], { queryParams: { id: task.task_detail.id, source: task.id } });
				}
			});
		} else if (task.task_type == '_PSFO_TASK_TYPE_VERIFY_ACTION') {
			this.http.POST('action?action=SV', task.task_detail).subscribe(res => {
				if (res.status === 200) {
					this.router.navigate(['rectification-task/verify-task'], { queryParams: { id: task.task_detail.id } });
				}
			});
		} else if (task.task_type == '_PSFO_TASK_TYPE_CLOSE_PERMIT') {
			this.http.POST('instance?action=SC', task.task_detail).subscribe(res => {
				if (res.status === 200) {
					this.router.navigate(['rectification-task/review-task'], { queryParams: { id: task.task_detail.id, source: task.id, title: 'check_close_audit' } });
				}
			});
		}

	}

	// 验证开始
	acceptStartPending(task: any, show: any) {
		if (task.task_type == '_PSFO_TASK_TYPE_VERIFY_ACTION') {
			if (show == 'accept') {
				this.http.POST('action?action=AV', task.task_detail).subscribe(res => {
					if (res.status === 200) {
						// this.router.navigate(['rectification-task/verify-task'], { queryParams: { id: task.task_detail.id } });
						this.ngOnInit();
					}
				});
			} else {
				this.http.POST('action?action=SV', task.task_detail).subscribe(res => {
					if (res.status === 200) {
						this.router.navigate(['rectification-task/verify-task'], { queryParams: { id: task.task_detail.id } });
					}
				});
			}
		} else if (task.task_type == '_PSFO_TASK_TYPE_CLOSE_PERMIT') {
			if (show == 'accept') {
				this.http.POST('instance?action=AC', task.task_detail).subscribe(res => {
					if (res.status === 200) {
						// this.router.navigate(['rectification-task/verify-task'], { queryParams: { id: task.task_detail.id } });
						this.ngOnInit();
					}
				});
			} else {
				this.http.POST('instance?action=SC', task.task_detail).subscribe(res => {
					if (res.status === 200) {
						this.router.navigate(['rectification-task/review-task'], { queryParams: { id: task.task_detail.id, source: task.id, title: 'check_close_audit' } });
					}
				});
			}
		} else if (task.task_type == '_PSFO_TASK_TYPE_REVIEW_INSPECTION') {
			if (show == 'accept') {
				this.http.POST('action?action=AR', task.task_detail).subscribe(res => {
					if (res.status === 200) {
						// this.router.navigate(['rectification-task/verify-task'], { queryParams: { id: task.task_detail.id } });
						this.ngOnInit();
					}
				});
			} else {
				this.http.POST('action?action=SR', task.task_detail).subscribe(res => {
					if (res.status === 200) {
						this.router.navigate(['rectification-task/verify-task'], { queryParams: { id: task.task_detail.id } });
					}
				});
			}
		} else {
			if (show == 'accept') {
				this.http.POST('action?action=AR', task.task_detail).subscribe(res => {
					if (res.status === 200) {
						// this.router.navigate(['rectification-task/verify-task'], { queryParams: { id: task.task_detail.id } });
						this.ngOnInit();
					}
				});
			} else {
				this.http.POST('action?action=SR', task.task_detail).subscribe(res => {
					if (res.status === 200) {
						this.router.navigate(['rectification-task/verify-task'], { queryParams: { id: task.task_detail.id } });
					}
				});
			}
		}

	}

	matMenuClick() {
		this.tasks = [];
		this.ngOnInit();
	}

	// 查看任务
	viewMore(task: any): void {
		this.navigateToPage(task);
	}

	// 前往任务中心
	viewAll(): void {
		this.router.navigate(['task-center']);
	}

	// 根据任务类型区分跳转到的页面
	navigateToPage(task: any): void {
		switch (task.task_type) {
			case '_PSFO_TASK_TYPE_CONDUCT_ACTION': // 执行整改
			this.router.navigate(['rectification-task'], { queryParams: { id: task.task_detail.id } });
			break;
			case '_PSFO_TASK_TYPE_VERIFY_ACTION': // 验证整改
			this.router.navigate(['rectification-task/verify-task'], { queryParams: { id: task.task_detail.id } });
			break;
			case '_PSFO_TASK_TYPE_REVIEW_INSPECTION': // 复审检查
			this.router.navigate(['custom-check/custom-check-add'], { queryParams: { id: task.task_detail.id, type: 'edit', goto: 'task' } });
			break;
			case '_PSFO_TASK_TYPE_REVIEW_INJECT': // 复审拒绝整改
			this.router.navigate(['rectification-task/review-task'], { queryParams: { id: task.task_detail.id, source: task.id } });
			break;
			case '_PSFO_TASK_TYPE_CLOSE_PERMIT': // 检查关闭审核
			this.router.navigate(['rectification-task/review-task'], { queryParams: { id: task.task_detail.id, source: task.id, title: 'check_close_audit' } });
			break;
		}
	}
}
