<!-- <kt-splash-screen *ngIf="loader"></kt-splash-screen> -->
<!-- <div *ngIf="showMenu">
	<system-header></system-header>
</div> -->
<div *ngIf="showMenu">
	<mega-menu
		displayField="menuCode"
		iconField="menuIcon"
		linkField="menuUrl"
		childrenField="children">
	</mega-menu>
</div>
<div class="content">
	<router-outlet></router-outlet>
</div>
<div class="mask" *ngIf="showMask">
	<div class="loader">
		<!-- <div class="loader-icon"></div> -->
		<mat-spinner></mat-spinner>
		<div class="loader-message">{{ 'LABEL_PLEASE_WAIT' | translate }}</div>
	</div>
</div>