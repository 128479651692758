// Modules
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable()
export class AuthGuardService implements CanActivate {
	token: string = '';

	constructor(private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		this.token = localStorage.getItem('token') ?? '';
		if (!this.token) {
			localStorage.clear();
			this.router.navigate(['login']);
			return false;
		}
		return true;
	}
}
