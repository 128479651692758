// Modules
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
// Services
import { TranslateService } from '@ngx-translate/core';
// Structs
import { MegaMenuItem, MenuItem } from 'primeng/api';
// Components
import { ChangePasswordComponent } from 'src/app/dialog/change-password/change-password.component';

@Component({
	selector: 'mega-menu',
	templateUrl: './mega-menu.component.html',
	styleUrls: ['./mega-menu.component.scss']
})
export class MegaMenuComponent implements OnInit {
	// Custom input fields for obtaining data for MegaMenu
	@Input() displayField: string = 'label';
	@Input() iconField: string = 'icon';
	@Input() linkField: string = 'routerLink';
	@Input() childrenField: string = 'items';
	// Property
	menus: any[] = [];
	roles: any[] = [];
	megaMenuList: MegaMenuItem[] = [];
	displayChinese: boolean = false;

	constructor(
		private router: Router,
		private translateService: TranslateService,
		private matDialog: MatDialog
		) {
		this.displayChinese = this.translateService.currentLang === 'zh' ? false : true;
	}

	ngOnInit(): void {
		// Get menu list from local storage
		const menus = JSON.parse(localStorage.getItem('menu') ?? '[]') ?? [];
		// Convert menu list into valid format for this component
		this.convertMegaMenuFormat(menus, this.megaMenuList);
		// Get role list from local storage
		this.roles = JSON.parse(localStorage.getItem('roles') ?? '[]') ?? [];
	}

	// 切换语言
	changeLanguage(): void {
		this.displayChinese = !this.displayChinese;
		if (this.translateService.currentLang === 'zh') {
			localStorage.setItem('language', 'en');
		} else {
			localStorage.setItem('language', 'zh');
		}
		this.translateService.use(localStorage.getItem('language') ?? 'en');
	}

	// Convert menu list obtained from API to the format that MegaMenu accepts
	convertMegaMenuFormat(menu: any, megaMenuList: MegaMenuItem[]): void {
		// If the original menu is empty or non-existent, we do nothing.
		if (!menu || menu.length === 0) {
			return;
		}

		menu.forEach((item: any) => {
			let megaMenuItem: MegaMenuItem = {
				label: item[this.displayField] ? this.translateService.instant('MENU.' + item[this.displayField]) : '',
				icon: item[this.iconField] ?? '',
				routerLink: item[this.linkField] ? item[this.linkField].split('/') : null,
				items: [] // We need to push children into this array manually
			};

			// Process each layer of data to match accepted format for MegaMenu
			if (item[this.childrenField] && item[this.childrenField].length > 0) {
				item[this.childrenField].forEach((column: any[]) => {
					const convertedColumn: MenuItem[] = [];
					column.forEach(category => {
						const convertedCategory: MenuItem = {
							label: category[this.displayField],
							icon: category[this.iconField],
							items: []
						};
						if (category[this.childrenField] && category[this.childrenField].length > 0) {
							category[this.childrenField].forEach((entry: any) => {
								const convertedEntry: MenuItem = {
									label: entry[this.displayField],
									icon: entry[this.iconField],
									routerLink: entry[this.linkField] ? entry[this.linkField].split('/') : ''
								};
								convertedCategory.items?.push(convertedEntry);
							});
						}
						convertedColumn.push(convertedCategory);
					});
					megaMenuItem.items?.push(convertedColumn);
				});
			}
			if (megaMenuList) {
				megaMenuList.push(megaMenuItem);
			} else {
				megaMenuList = [megaMenuItem];
			}
		});
	}

	// MegaMenu's sub menu only supports up to 4 columns, so we'll calculate
	// the optimal column count for each grid
	getSubMenuColumnCount(subMenuCategoryList: any[]): number {
		if (!subMenuCategoryList || subMenuCategoryList.length === 0) {
			return 0;
		}
		switch (true) {
			case subMenuCategoryList.length <= 2:
			return subMenuCategoryList.length;

			case subMenuCategoryList.length <= 4:
			return 2;

			case subMenuCategoryList.length <= 9:
			return 3;

			case subMenuCategoryList.length > 9:
			return 4;

			default:
			return 0;
		}
	}

	changePassword(): void {
		const dialogRef = this.matDialog.open(ChangePasswordComponent, {
			width: '900px',
			disableClose: true
		});

		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.logOut();
			}
		});
	}

	logOut(): void {
		localStorage.clear();
		this.router.navigate(['login']);
	}
}
