<div class="shared-table container" [style.margin-top.px]="pxMarginTop" [style.margin-bottom.px]="pxMarginBottom" [style.margin-top.rem]="remMarginTop" [style.margin-bottom.rem]="remMarginBottom">
	<!-- 按钮组 -->
	<div class="flex-btn-group">
		<div class="button-group" *ngFor="let button of leftButtonGroup" [ngSwitch]="button">
			<button mat-button
				*ngSwitchCase="ActionType.MULTI_DELETE"
				(click)="multiRowsAction(button)" 
				class="btn btn-helm-grey delete-btn-left"
				>{{ 'LABEL_DELETE' | translate}}
			</button>
			<button mat-button
				*ngSwitchCase="ActionType.ADD"
				(click)="multiRowsAction(button)"
				class="responsive-button btn btn-helm-grey add-btn-left"
				>{{ 'LABEL_CREATE' | translate}}
			</button>
			<button mat-button
				*ngSwitchCase="ActionType.EXPORT"
				(click)="multiRowsAction(button)"
				class="responsive-button btn btn-helm-grey add-btn-left"
				>{{ 'LABEL_EXPORT' | translate}}
			</button>
		</div>
		<div class="button-group" *ngFor="let button of rightButtonGroup" [ngSwitch]="button">
			<button mat-button
				*ngSwitchCase="ActionType.MULTI_DELETE"
				(click)="multiRowsAction(button)"
				class="btn btn-helm-grey delete-btn-right"
				>{{ 'LABEL_DELETE' | translate}}
			</button>
			<button mat-button
				*ngSwitchCase="ActionType.ADD"
				(click)="multiRowsAction(button)"
				class="btn btn-helm-grey add-btn-right"
				>{{ 'LABEL_CREATE' | translate}}
			</button>
			<button mat-button
				*ngSwitchCase="ActionType.EXPORT"
				(click)="multiRowsAction(button)"
				class="btn btn-helm-grey add-btn-right"
				>{{ 'LABEL_EXPORT' | translate}}
			</button>
		</div>
	</div>
	
	<!-- 表格 -->
	<table id="tab01" mat-table matSort multiTemplateDataRows [dataSource]="dataSource" class="m-table matHeaderTable" (matSortChange)="sort($event)">
		<!-- 列 -->
		<ng-container *ngFor="let column of columns" matColumnDef="{{ column.columnDef }}">
			<!-- 列头 -->
			<th mat-sort-header [disabled]="column.noSort" [class.actionHeader]="column.type === 'action'" mat-header-cell *matHeaderCellDef>
				<div *ngIf="column.type !== 'checkbox'" class="header" style="color: #333333 !important; font-family: Noto Sans CJK SC !important; opacity: 1 !important; font-weight: 600;">{{ column.header | translate }}</div>
				<div *ngIf="column.type === 'checkbox' || column.type === 'totalCheckbox'" style="padding: 0 !important;">
					<mat-checkbox (change)="$event ? masterToggle() : null"
						[checked]="selection.hasValue() && isAllSelected()"
						[indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
					</mat-checkbox>
				</div>
			</th>		
			<!-- 列单元 -->
			<td [ngClass]="{'checkboxWidth': column.type === 'checkbox'}" mat-cell [class.actionHeader]="column.type === 'action'" *matCellDef="let row;let i = index;" [ngSwitch]="column.type">
				<ng-container *ngSwitchCase="'checkbox'">
					<mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
						[checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
					</mat-checkbox>
				</ng-container>
				<!-- 文字 -->
				<ng-container *ngSwitchCase="'text'" class="row">{{ row[column.columnDef] }}</ng-container>
				<!-- code翻译 -->
				<ng-container *ngSwitchCase="'code'" class="row">{{ row[column.columnDef] | translateCode }}
				</ng-container>
				<!-- 时间 -->
				<ng-container *ngSwitchCase="'date'" class="row">
					{{ row[column.columnDef] | date: 'yyyy-MM-dd' }}</ng-container>
				<ng-container *ngSwitchCase="'date-time'" class="row">
					{{ row[column.columnDef] | date: 'yyyy-MM-dd HH:mm:ss' }}</ng-container>
				<ng-container *ngSwitchCase="'custom-date'" class="row">
					{{ row[column.columnDef] | date: column.data_format }}</ng-container>
				<!-- 操作栏 -->
				<ng-container *ngSwitchCase="'action'">
					<div class="action action-row">
						<mat-icon *ngIf="canShowAction(ActionType.VIEW) && !row.hiddenView" aria-hidden="false" (click)="view(row)">remove_red_eye</mat-icon>
						<mat-icon *ngIf="canShowAction(ActionType.EDIT) && !row.hiddenEdit" aria-hidden="false" (click)="edit(row)">edit</mat-icon>
						<mat-icon *ngIf="canShowAction(ActionType.DELETE) && !row.hiddenDelete" aria-hidden="false" (click)="delete(row)">delete</mat-icon>
						<mat-icon *ngIf="canShowAction(ActionType.DISABLE) && !row.hiddenDisabled" aria-hidden="false" (click)="disabled(row)">block</mat-icon>

						<!-- 计划性检查 -->
						<mat-icon *ngIf="canShowAction(ActionType.START) && !row.hiddenStart" title="{{LABEL_START | translate}}" aria-hidden="false" (click)="start(row)">play_arrow</mat-icon>
						<mat-icon *ngIf="canShowAction(ActionType.END) && !row.hiddenEnd" title="{{LABEL_END | translate}}" aria-hidden="false" (click)="end(row)">clear</mat-icon>
						<mat-icon *ngIf="canShowAction(ActionType.STOP) && !row.hiddenStop" title="{{LABEL_STOP | translate}}" aria-hidden="false" (click)="stop(row)">stop</mat-icon>
						<mat-icon *ngIf="canShowAction(ActionType.PAUSE) && !row.hiddenPause" title="{{LABEL_PAUSE | translate}}" aria-hidden="false" (click)="pause(row)">pause</mat-icon>
					</div>
				</ng-container>
			</td>
		</ng-container>
		<!-- 列 -->
		<!-- 折叠层 -->
		<ng-container [class.hidden]="!canExpand" matColumnDef="expandedDetail">
			<td class="example-element-detail-td" mat-cell *matCellDef="let element" [hidden]="element !== expandedElement" [attr.colspan]="displayedColumns.length">
				<div style="display: block !important;" class="example-element-detail" [@detailExpand]="'expanded'">
					<!-- [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" -->
					<table
						[dataSource]="element.childDataSource"
						[displayedColumns]="element.childDisplayedColumns"
						[columns]="element.childColumns"
						[actions]="element.actions"
						[hasFoot]="false"
						[canExpand]="element.canExpand"
						(viewResult)="viewChild($event)"
						>
					</table>
				</div>
			</td>
		</ng-container>
		<!-- 折叠层 -->
		<tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
		<tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
			[class.example-expanded-row]="expandedElement === element"
			(click)="expandedElement = expandedElement === element ? null : element">
		</tr>
		<tr [hidden]="!canExpand" mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row" [class.height1]="expandedElement === null"></tr>
	</table>

	<!-- 分页 -->
	<mat-paginator *ngIf="hasFoot"
		#paginator
		[length]="totalCount"
		[pageSize]="pageSize"
		[pageSizeOptions]="[5,10,20,50,100]"
		[pageIndex]="pageIndex"
		(page)="page($event)"
		showFirstLastButtons
		>
	</mat-paginator>
</div>