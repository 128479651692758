// Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputNumberModule } from 'primeng/inputnumber';
// Services
import { InterceptService } from 'src/app/shared/services/intercept.service';
import { AuthGuardService } from 'src/app/shared/services/auth-guard.service';
// Components
import { AppComponent } from 'src/app/app.component';
import { LoginComponent } from 'src/app/page/login/login.component';
import { SelectFundComponent } from 'src/app/page/external/select-fund/select-fund.component';
import { ChangePasswordComponent } from 'src/app/dialog/change-password/change-password.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
	declarations: [
	AppComponent,
	LoginComponent,
	SelectFundComponent,
	ChangePasswordComponent
	],
	imports: [
	BrowserModule,
	AppRoutingModule,
	BrowserAnimationsModule,
	HttpClientModule,
	ReactiveFormsModule,
	SharedModule,
	DropdownModule,
	MultiSelectModule,
	InputNumberModule,
	TranslateModule.forRoot({
		loader: {
			provide: TranslateLoader,
			useFactory: (createTranslateLoader),
			deps: [HttpClient]
		}
	})
	],
	providers: [
	{
		// http请求拦截器
		provide: HTTP_INTERCEPTORS,
		useClass: InterceptService,
		multi: true
	},
	{
		provide: LocationStrategy,
		useClass: HashLocationStrategy
	},
	AuthGuardService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
