// Modules
import { Injectable } from '@angular/core';
// Structs
import { BasicConfig } from 'src/app/shared/basic-config';

@Injectable({
	providedIn: 'root'
})
export class GeneralService {

	constructor() { }

	/**
	 * 转换列表数据为兼容下拉组件的格式并返回
	 * @originalList 原始列表数据
	 * @displayField 数据展示字段
	 * @valueField 数值绑定字段
	 * @returns 转换完成后的列表数据
	 */
	convertDropdownFormat(originalList: any[], displayField?: string, valueField?: string): any[] {
		if (!originalList || originalList.length === 0) {
			return [];
		}
		let resultList: any[] = [];
		originalList.forEach(item => {
			const convertItem: any = {};
			if (displayField) {
				convertItem[displayField] = item;
			} else {
				convertItem['displayField'] = item;
			}
			if (valueField) {
				convertItem[valueField] = item;
			} else {
				convertItem['valueField'] = item;
			}
			resultList.push(convertItem);
		});
		return resultList;
	}

	/**
	 * 将列表中同一字段的所有值拼接并返回
	 * @list 原始列表数据
	 * @concatField 列表中需要拼接的字段
	 * @returns 拼接完成的数据
	 */
	concatListField(list: any[], concatField: string): string {
		if (!list || !concatField || list.length === 0) {
			return '';
		}
		let result: string = '';
		list.forEach((item, index) => {
			result += item[concatField];
			if (index !== list.length - 1) {
				result += ',';
			}
		});
		return result;
	}

	/**
	 * 设定localStorage中当前页面的菜单地址
	 * @url 需要设定的地址
	 */
	setCurrentMenuUrl(url: string): void {
		localStorage.setItem('currentUrl', url);
	}

	/**
	 * 判断是否允许此次跳转
	 * @externalLink 跳转目的链接
	 * @returns 跳转是否允许
	 */
	redirectAllowed(externalLink: string): boolean {
		const allowedExternals: string[] = BasicConfig.allowedExternal;
		const matchResult = allowedExternals.filter(link => externalLink.indexOf(link) > -1);
		return matchResult.length > 0;
	}

	/**
	 * 多选输入组件数据显示不同数值
	 * @data 原始下拉数据
	 * @propertyDisplay 需要显示的字段
	 * @propertyValue 需要绑定的字段
	 * @returns 转换结束后的下拉数据
	 */
	getMatSelectAutocompleteDisplayValueDiff(data: any[], propertyDisplay: string, propertyValue: string): any[] {
		let output: any[] = [];
		if (data) {
			for (let item of data) {
				output.push({
					label: item[propertyDisplay],
					value: item[propertyValue]
				});
			}
		}
		return output;
	}

	/**
	 * 将时间转换至当天末尾
	 * @date 待转换的时间
	 * @returns 转换结束后的时间
	 */
	getEndOfDay(date: string | Date): string {
		let convertedDate: Date = new Date(date);
		convertedDate.setHours(23, 59, 59);
		return convertedDate.toISOString();
	}
}
