// Modules
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'delete-confirm',
	templateUrl: './delete-confirm.component.html',
	styleUrls: ['./delete-confirm.component.scss']
})
export class DeleteConfirmComponent {

	constructor(
		public dialogRef: MatDialogRef<DeleteConfirmComponent>
		) { }

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	onConfirm(): void {
		this.dialogRef.close(true);
	}
}
