// Modules
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { Md5 } from 'ts-md5/dist/md5';
import { ActivatedRoute, Params } from '@angular/router';
// Services
import { MessageService } from 'src/app/shared/services/message.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { GeneralService } from 'src/app/shared/services/general.service';
import { TranslateService } from '@ngx-translate/core';
// Structs
import { StatusCode } from 'src/app/shared/structs/status-code.struct';
import { BasicConfig } from 'src/app/shared/basic-config';
import { SnackBarType } from 'src/app/shared/structs/snack-bar-type.struct';

@Component({
	selector: 'select-fund',
	templateUrl: './select-fund.component.html',
	styleUrls: ['./select-fund.component.scss']
})
export class SelectFundComponent implements OnInit {
	originalReferrer: string = '';
	fundList: any[] = [];
	authorized: boolean = false;
	signature: string = '';
	sku: string = '';

	fundForm: FormGroup = this.formBuilder.group({
		funds: new FormControl(null, Validators.required)
	});

	constructor(
		private route: ActivatedRoute,
		private translateService: TranslateService,
		private messageService: MessageService,
		private generalService: GeneralService,
		private formBuilder: FormBuilder,
		private productService: ProductService
		) {
		this.route.queryParams.subscribe((params: Params) => {
			this.sku = params.sku ?? '';
			this.signature = params.signature ?? '';
		});
	}

	ngOnInit(): void {
		// 展示用途，暂时放开校验
		this.authorized = true;
		this.translateService.setDefaultLang('en'); // 设置当前的默认语言类型
		if (!localStorage.getItem('language')) {
			localStorage.setItem('language', 'en');
		}
		this.translateService.use(localStorage.getItem('language') ?? 'en'); // 设置使用的语言类型
		// 等待翻译文件加载完成后再进行校验
		this.translateService.getTranslation(localStorage.getItem('language') ?? 'en').subscribe(load => {
			// 校验Referrer原网址为允许连接的地址
			// this.originalReferrer = document.referrer;
			// if (!(this.authorized = this.generalService.redirectAllowed(this.originalReferrer))) {
			// 	this.messageService.openSnackBar('ERROR.ORIGIN_NO_AUTHORIZATION', SnackBarType.ERROR);
			// 	setTimeout(() => {
			// 		this.back();
			// 	}, BasicConfig.duration);
			// } else {
				// 校验签名确保一致
				// const md5Value = new Md5().start().appendStr(BasicConfig.externalValidationKey).appendStr(this.sku).appendStr(BasicConfig.externalValidationKey).end() as string;
				// if (!(this.authorized = md5Value === this.signature)) {
				// 	this.messageService.openSnackBar('ERROR.INVALID_SIGNATURE', SnackBarType.ERROR);
				// 	setTimeout(() => {
				// 		this.back();
				// 	}, BasicConfig.duration);
				// } else {
					this.getFundList();
					this.getSelectedFunds();
				// }
			// }
		});
	}

	async getFundList(): Promise<any> {
		const that: this = this;
		const promise = new Promise((resolve, reject) => {
			that.productService.getFundListForNetSuite().subscribe(res => {
				if (res && res.code === StatusCode.OK) {
					that.fundList = res.data ?? [];
					resolve(null);
				} else {
					that.messageService.openSnackBar(res.msg ?? 'ERROR.UNKNOWN_ERROR', SnackBarType.ERROR);
					reject();
				}
			}, err => {
				that.messageService.openSnackBar('ERROR.UNKNOWN_ERROR', SnackBarType.ERROR);
				reject();
			});
		});
		return promise;
	}

	async getSelectedFunds(): Promise<any> {
		const queryData = {
			productSku: this.sku
		};
		const that: this = this;
		const promise = new Promise((resolve, reject) => {
			that.productService.getSelectedFunds(queryData).subscribe(res => {
				if (res && res.code === StatusCode.OK) {
					const selectedFundIds: string = (res.data ?? []).map((fund: any) => fund.fundId);
					that.fundForm.controls.funds.setValue(selectedFundIds);
					resolve(null);
				} else {
					that.messageService.openSnackBar(res.msg ?? 'ERROR.UNKNOWN_ERROR', SnackBarType.ERROR);
					reject();
				}
			}, err => {
				that.messageService.openSnackBar('ERROR.UNKNOWN_ERROR', SnackBarType.ERROR);
				reject();
			});
		});
		return promise;
	}

	validationPassed(ngForm: NgForm): boolean {
		if (ngForm.form.invalid) {
			if (ngForm.form.controls.funds.errors) {
				switch (Object.keys(ngForm.form.controls.funds.errors)[0]) {
					case 'required':
					this.messageService.openSnackBar('ERROR.REQUIRED', SnackBarType.ERROR);
					break;
				}
			}
			return false;
		} else {
			return true;
		}
	}

	async submit(ngForm: NgForm): Promise<any> {
		if (this.validationPassed(ngForm)) {
			const fundInfo: any = {
				productSku: this.sku,
				funds: ngForm.form.controls.funds.value.join(',')
			};
			const that: this = this;
			const promise = new Promise((resolve, reject) => {
				that.productService.addFundProduct(fundInfo).subscribe(res => {
					if (res && res.code === StatusCode.OK) {
						that.messageService.openSnackBar('SYSTEM.UPDATE_SUCCESS');
						setTimeout(() => {
							that.back();
						}, BasicConfig.duration);
						resolve(null);
					} else {
						that.messageService.openSnackBar(res.msg ?? 'ERROR.UNKNOWN_ERROR', SnackBarType.ERROR);
						reject();
					}
				}, err => {
					that.messageService.openSnackBar('ERROR.UNKNOWN_ERROR', SnackBarType.ERROR);
					reject();
				});
			});
			return promise;
		}
	}

	back(): void {
		document.location.href = this.originalReferrer;
	}
}
