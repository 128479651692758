// Modules
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// Services
import { HttpHelperService } from 'src/app/shared/services/http-helper.service';

@Injectable({
	providedIn: 'root'
})
export class LoginService {

	constructor(
		private http: HttpHelperService
	) { }

	getAuthToken(username: string, password: string): Observable<any> {
		return this.http.POST(`Token/auth`, {username: username, password: password});
	}

	login(username: string, password: string): Observable<any> {
		return this.http.POST(`User/Login`, {username: username, password: password});
	}
}
