// Modules
import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
// Structs
import { ListFilterItem } from 'src/app/shared/structs/list-filter-item.struct';
import { FilterType } from 'src/app/shared/structs/filter-type.struct';
import { BasicConfig } from 'src/app/shared/basic-config';

@Component({
	selector: 'query-fields',
	templateUrl: './query-fields.component.html',
	styleUrls: ['./query-fields.component.scss']
})
export class QueryFieldsComponent implements OnInit, OnChanges {
	@Input() fields: ListFilterItem[] = []; // 查询条件
	@Input() query: any = {}; // 查询对象
	@Input() pxMarginTop: number = 0;
	@Input() pxMarginBottom: number = 0;
	@Input() remMarginTop: number = 0;
	@Input() remMarginBottom: number = 0;
	@Input() pxPaddingTop: number = 0;
	@Input() pxPaddingBottom: number = 0;
	@Input() remPaddingTop: number = 0;
	@Input() remPaddingBottom: number = 0;

	@Output() searchResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() clearResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() clearCascadeResult: EventEmitter<any> = new EventEmitter<any>();

	// Store a reference to the enum
	FilterType = FilterType;
	BasicConfig = BasicConfig;

	timeOut: number = 0;

	constructor() { }

	ngOnInit(): void { }

	ngOnChanges(changes: SimpleChanges): void {
		for (let attr in changes) {
			switch(attr) {
				case 'fields':
				this.fields = changes[attr].currentValue;
				changes[attr].currentValue.forEach((item: any, index: number) => {
					if (changes[attr].currentValue[index]?.options?.length < changes[attr].previousValue[index]?.options?.length) {
						this.clearCascadeResult.emit(item?.ngModel);
					}
				});
				break;
			}
		}
	}

	// 筛选下拉框内的数据
	filterSelect(value: string, index: number, key: string): void {
		if (this.timeOut) {
			clearTimeout(this.timeOut);
			this.timeOut = setTimeout(() => {
				this.fields[index].options = this.fields[index].options.filter((res: any) => key ? res[key].indexOf(value) !== -1 : res.indexOf(value) !== -1);
			});
		} else {
			this.timeOut = setTimeout(() => {
				this.fields[index].options = this.fields[index].options.filter((res: any) => key ? res[key].indexOf(value) !== -1 : res.indexOf(value) !== -1);
			});
		}
	}

	// 重置事件
	resetSearch(): void {
		this.query = {};
		this.clearResult.emit(this.query);
	}

	// 搜索事件
	searchList(): void {
		this.searchResult.emit(this.query);
	}
}
