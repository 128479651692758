// Modules
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationCancel, Router } from '@angular/router';
import { Subscription } from 'rxjs';
// Services
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/shared/services/message.service';
// Structs
import { BasicConfig } from 'src/app/shared/basic-config';
import { SnackBarType } from 'src/app/shared/structs/snack-bar-type.struct';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	showMenu = false;
	showMask: boolean = false;
	showMaskTimeOut: any;
	currentLanguage: string = '';
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	constructor(
		private router: Router,
		private translateService: TranslateService,
		private messageService: MessageService,
		private changeDetectorRef: ChangeDetectorRef
		) {
		this.translateService.setDefaultLang('en'); // 设置当前的默认语言类型
		if (!localStorage.getItem('language')) {
			localStorage.setItem('language', 'en');
		}
		this.translateService.use(localStorage.getItem('language') ?? 'en'); // 设置使用的语言类型
		this.router.events.subscribe((res: any) => {
			if (res instanceof NavigationEnd) {
				if (res['urlAfterRedirects']) {
					const noMenuMatchResult = BasicConfig.noMenuComponent.filter(page => res['urlAfterRedirects'].indexOf(page) > -1);
					this.showMenu = noMenuMatchResult.length === 0;
				}
			} else if (res instanceof NavigationCancel) {
				this.messageService.openSnackBar(res['reason'], SnackBarType.ERROR);
			}
		});
	}

	ngOnInit(): void {
		// 监听是否显示遮罩层
		const showMaskSubscription = this.messageService.showMaskObservable.subscribe(res => {
			if (res === false) {
				if (!this.showMaskTimeOut) {
					this.showMaskTimeOut = setTimeout(() => {
						this.showMask = res;
						this.changeDetectorRef.markForCheck();
						this.changeDetectorRef.detectChanges();
					}, BasicConfig.spinnerDelay);
				} else {
					clearTimeout(this.showMaskTimeOut);
					this.showMaskTimeOut = setTimeout(() => {
						this.showMask = res;
						this.changeDetectorRef.markForCheck();
						this.changeDetectorRef.detectChanges();
					}, BasicConfig.spinnerDelay);
				}
			} else {
				this.showMask = res;
				this.changeDetectorRef.markForCheck();
				this.changeDetectorRef.detectChanges();
			}
		});
		this.unsubscribe.push(showMaskSubscription);
	}
}
