// Modules
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent, FilterMatchMode } from 'primeng/api';
import { Table } from 'primeng/table';
// Structs
import { ActionType } from 'src/app/shared/structs/action-type.struct';
import { FilterType } from 'src/app/shared/structs/filter-type.struct';
import { CustomTableHeaderActionItem } from 'src/app/shared/structs/custom-table-header-action-item.struct';
import { TableQueryData } from 'src/app/shared/structs/table-query-data.struct';
import { TableColumnItem } from 'src/app/shared/structs/table-column-item.struct';
import { CustomActionItem } from 'src/app/shared/structs/custom-action-item.struct';
import { TableRowAction } from 'src/app/shared/structs/table-row-action.struct';
import { BasicConfig } from 'src/app/shared/basic-config';

@Component({
	selector: 'extend-table',
	templateUrl: './extend-table.component.html',
	styleUrls: ['./extend-table.component.scss'],
	animations: [
	trigger('detailExpand', [
		state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
		state('expanded', style({ height: '*' })),
		transition('expanded <=> collapsed', animate('50ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})
export class ExtendTableComponent implements OnInit, OnChanges {
	// Store a reference to the enum
	ActionType = ActionType;
	FilterType = FilterType;
	BasicConfig = BasicConfig;

	@Input() dataSource: any[] = [];
	@Input() uniqueKey: string = ''; // 独特字段
	@Input() columns: TableColumnItem[] = []; // 列数据
	@Input() displayedColumns: any[] = []; // 列名
	@Input() selectedColumns: any[] = []; // 已选中显示列
	@Input() selectedDataRows: any[] = []; // 已选中数据
	@Input() pageSize: number = 10;
	@Input() pageIndex: number = 0;
	@Input() leftButtonGroup: Array<ActionType> = new Array(); // 列表左侧头部按钮
	@Input() rightButtonGroup: Array<ActionType> = new Array(); // 列表右侧头部按钮
	@Input() leftCustomButtonGroup: Array<CustomTableHeaderActionItem> = new Array(); // 列表左侧头部按钮（可自定义）
	@Input() rightCustomButtonGroup: Array<CustomTableHeaderActionItem> = new Array(); // 列表右侧头部按钮（可自定义）
	@Input() canExpand = false; // 是否有折叠功能
	@Input() paginator = true; // 是否有底部分页
	@Input() columnFilter = false; // 是否支持列内筛选
	@Input() currentPageReport = false; // 是否展示分页信息
	@Input() customPageReport = false; // 自定义分页信息
	@Input() rowsPerPageOptions: number[] | undefined = undefined; // 自定义分页大小选项
	@Input() currentPageReportTemplate: string = this.translateService.instant('SYSTEM.PAGE_REPORTING_TEMPLATE'); // 分页信息展示模板
	@Input() actions: ActionType[] = []; // 操作栏（无条件判断）
	@Input() rowActions: TableRowAction[] = []; // 操作栏（有条件判断）
	@Input() emptyMessage: string = this.translateService.instant('SYSTEM.NO_DATA');
	@Input() pxMarginTop: number = 0;
	@Input() pxMarginBottom: number = 0;
	@Input() remMarginTop: number = 0;
	@Input() remMarginBottom: number = 0;
	@Input() pxPaddingTop: number = 0;
	@Input() pxPaddingBottom: number = 0;
	@Input() remPaddingTop: number = 0;
	@Input() remPaddingBottom: number = 0;
	@Input() totalRecords: number = 0; // 总数据量
	@Input() selectionMode: string = 'multiple'; // 选择模式
	@Input() customActions: CustomActionItem[] = []; // 自定义操作
	@Input() pageReportRightPosition: string = ''; // 自定义数据信息位置

	// Legacy local data manipulation, not being used anymore
	@Output() sortResult: EventEmitter<any> = new EventEmitter<any>(); // 排序
	@Output() pageResult: EventEmitter<any> = new EventEmitter<any>(); // 翻页
	@Output() filterResult: EventEmitter<any> = new EventEmitter<any>(); // 筛选
	
	// New data manipulation
	@Output() tableButtonResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() tableCustomButtonResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() selectionResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() lazyLoadFunction: EventEmitter<any> = new EventEmitter<any>();

	// Actions
	@Output() viewResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() editResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() deleteResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() disableResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() startResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() endResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() stopResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() pauseResult: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('pdt') pdt!: Table;

	totalPages: number = 0;
	customPageReportMessage: string = '';
	loading: boolean = true;
	lazyLoadEvent: LazyLoadEvent = {};

	selection: any[] = []; // checkbox选择
	expandedElement: any; // 折叠层

	constructor(
		private translateService: TranslateService
		) {
	}

	ngOnInit(): void {
		if (Array.isArray(this.selectedDataRows) && this.selectedDataRows.length > 0) {
			this.selection = this.selectedDataRows;
		}
		this.loading = false;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.totalRecords) {
			this.totalPages = Math.max(Math.ceil(this.totalRecords / this.pageSize), 1);
			this.customPageReportMessage = this.translateService.instant('SYSTEM.PAGE_REPORTING_TEMPLATE');
			this.customPageReportMessage = this.customPageReportMessage.replace('{totalRecords}', this.totalRecords.toString()).replace('{totalPages}', this.totalPages.toString());
		}
	}

	multiRowsAction(type: ActionType, event?: any): void {
		const selectedRows = this.selection;
		this.tableButtonResult.emit({ action: type, rows: selectedRows, event: event });
	}

	multiRowsCustomAction(type: CustomTableHeaderActionItem, event?: any) {
		const selectedRows = this.selection;
		this.tableCustomButtonResult.emit({ action: type.type, rows: selectedRows, event: event });
	}

	// 判断是否全选
	isAllSelected(): boolean {
		const numSelected = this.selection.length;
		const numRows = this.dataSource.length;
		return numSelected >= numRows;
	}

	// 全选/全不选
	masterToggle(event: any): void {
		this.selectionResult.emit(this.selection);
	}

	// 单个选中/反选
	toggle(event: any): void {
		this.selectionResult.emit(this.selection);
	}

	// ======================================================
	// Legacy local data manipulation, not being used anymore
	// 排序
	sort(event: LazyLoadEvent): void {
		this.sortResult.emit(event);
	}

	// 分页
	page(event: LazyLoadEvent): void {
		this.pageResult.emit(event);
	}

	// 筛选
	filter(event: LazyLoadEvent): void {
		this.filterResult.emit(event);
	}
	// Legacy local data manipulation ends
	// ======================================================

	// Whether an action button can be displayed
	canShowAction(type: ActionType): boolean {
		if (!this.actions || this.actions.length === 0) {
			return false;
		} else {
			return this.actions.indexOf(type) !== -1;
		}
	}

	// Whether an action button can be displayed
	isActionAllowed(action: TableRowAction) {
		if (!this.rowActions || this.rowActions.length === 0) {
			return false;
		} else {
			const filteredActions: TableRowAction[] = this.rowActions.filter(rowAction => rowAction.action === action.action && !rowAction.actionDisabled);
			return filteredActions.length > 0;
		}
	}

	// 懒加载（分页+排序+筛选）
	lazyLoad(event: LazyLoadEvent) {
		let awaitProcessEvent: LazyLoadEvent = {};
		// First determine whether this event is an instance of LazyLoadEvent
		// since LazyLoadEvent will also have a valid 'filters' attribute
		if (event.filters) {
			this.lazyLoadEvent = event;
			// Clear search if this method is triggered by clearing filtering in table
			this.cleanupFilterContent(event);
		} else {
			this.updateLazyLoadEvent();
		}
		// Make a copy of the event so we don't contaminate the original copy
		awaitProcessEvent = JSON.parse(JSON.stringify(this.lazyLoadEvent));
		let processedParams = this.processLazyLoadParameters(awaitProcessEvent);
		this.lazyLoadFunction.emit(processedParams);
	}

	// Actions
	performAction(action: ActionType, row: any): void {
		switch (action) {
			case ActionType.VIEW:
			this.view(row);
			break;

			case ActionType.EDIT:
			this.edit(row);
			break;

			case ActionType.DELETE:
			this.delete(row);
			break;

			case ActionType.DISABLE:
			this.disable(row);
			break;

			case ActionType.START:
			this.start(row);
			break;

			case ActionType.END:
			this.end(row);
			break;

			case ActionType.PAUSE:
			this.pause(row);
			break;

			case ActionType.STOP:
			this.stop(row);
			break;
		}
	}

	view(row: any): void {
		this.viewResult.emit(row);
	}

	edit(row: any): void {
		this.editResult.emit(row);
	}

	delete(row: any): void {
		this.deleteResult.emit(row);
	}

	disable(row: any): void {
		this.disableResult.emit(row);
	}

	start(row: any): void {
		this.startResult.emit(row);
	}

	end(row: any): void {
		this.endResult.emit(row);
	}

	pause(row: any): void {
		this.pauseResult.emit(row);
	}

	stop(row: any): void {
		this.stopResult.emit(row);
	}

	// 清除表格内筛选时某些类型需要进行手动清除
	cleanupFilterContent(event: LazyLoadEvent) {
		for (let attr in event.filters) {
			const column = this.columns.filter(column => column.filterDef === attr)[0];
			switch (column.filterType) {
				// Bind values for these types need to be cleaned up manually
				case FilterType.SINGLE_SELECT:
				case FilterType.MULTI_SELECT:
				case FilterType.RADIO_BUTTON:
				case FilterType.DATE_SELECT:
				if (!event.filters[attr].value) {
					column.filterValueSelection = null;
				}
				break;
			}
		}
	}

	// 将表格LazyLoadEvent格式转为API需要使用的格式
	processLazyLoadParameters(originalEvent: LazyLoadEvent): any {
		let result: TableQueryData = {};
		// Process paging
		result.paging = {
			firstIndex: originalEvent.first,
			pageSize: originalEvent.rows
		};
		// Process sorting
		result.sorting = {
			field: originalEvent.sortField,
			order: originalEvent.sortOrder
		};
		// Process filtering
		result.filtering = [];
		for (let attr in originalEvent.filters) {
			if (originalEvent.filters[attr].value && originalEvent.filters[attr].matchMode !== 'null') {
				const filterType = this.columns.filter(column => column.filterDef === attr)[0].filterType;
				switch (filterType) {
					// If FilterType.BLANK code won't reach here
					case FilterType.NUMBER:
					switch (originalEvent.filters[attr].matchMode) {
						case FilterMatchMode.EQUALS:
						result.filtering.push({
							key: attr,
							value: originalEvent.filters[attr].value,
							condition: '='
						});
						break;

						case FilterMatchMode.NOT_EQUALS:
						result.filtering.push({
							key: attr,
							value: originalEvent.filters[attr].value,
							condition: '!='
						});
						break;

						case FilterMatchMode.LESS_THAN:
						result.filtering.push({
							key: attr,
							value: originalEvent.filters[attr].value,
							condition: '<'
						});
						break;

						case FilterMatchMode.LESS_THAN_OR_EQUAL_TO:
						result.filtering.push({
							key: attr,
							value: originalEvent.filters[attr].value,
							condition: '<='
						});
						break;

						case FilterMatchMode.GREATER_THAN:
						result.filtering.push({
							key: attr,
							value: originalEvent.filters[attr].value,
							condition: '>'
						});
						break;

						case FilterMatchMode.GREATER_THAN_OR_EQUAL_TO:
						result.filtering.push({
							key: attr,
							value: originalEvent.filters[attr].value,
							condition: '>='
						});
						break;
					}
					break;

					case FilterType.DATE_SELECT:
					switch (originalEvent.filters[attr].matchMode) {
						case FilterMatchMode.DATE_IS:
						result.filtering.push({
							key: attr,
							value: originalEvent.filters[attr].value,
							condition: '='
						});
						break;

						case FilterMatchMode.DATE_IS_NOT:
						result.filtering.push({
							key: attr,
							value: originalEvent.filters[attr].value,
							condition: '!='
						});
						break;

						case FilterMatchMode.DATE_BEFORE:
						result.filtering.push({
							key: attr,
							value: originalEvent.filters[attr].value,
							condition: '<'
						});
						break;

						case FilterMatchMode.DATE_AFTER:
						result.filtering.push({
							key: attr,
							value: originalEvent.filters[attr].value,
							condition: '>'
						});
						break;
					}
					break;

					// They all have the same matchMode options
					case FilterType.INPUT:
					case FilterType.SINGLE_SELECT:
					case FilterType.MULTI_SELECT:
					case FilterType.RADIO_BUTTON:
					switch (originalEvent.filters[attr].matchMode) {
						case FilterMatchMode.STARTS_WITH:
						result.filtering.push({
							key: attr,
							value: originalEvent.filters[attr].value,
							condition: ':='
						});
						break;

						case FilterMatchMode.CONTAINS:
						result.filtering.push({
							key: attr,
							value: originalEvent.filters[attr].value,
							condition: 'like'
						});
						break;

						case FilterMatchMode.NOT_CONTAINS:
						result.filtering.push({
							key: attr,
							value: originalEvent.filters[attr].value,
							condition: 'not like'
						});
						break;

						case FilterMatchMode.ENDS_WITH:
						result.filtering.push({
							key: attr,
							value: originalEvent.filters[attr].value,
							condition: '=:'
						});
						break;

						case FilterMatchMode.EQUALS:
						result.filtering.push({
							key: attr,
							value: originalEvent.filters[attr].value,
							condition: '='
						});
						break;

						case FilterMatchMode.NOT_EQUALS:
						result.filtering.push({
							key: attr,
							value: originalEvent.filters[attr].value,
							condition: '!='
						});
						break;
					}
					break;

					//  Not being used
					case FilterType.BOOLEAN:
					break;
				}
			}
		}
		return result;
	}

	// Update lazy load event with selected values
	updateLazyLoadEvent(): void {
		for (let column of this.columns) {
			// We need to insert this filtering value into lazyLoadEvent
			if (column.filterValueSelection) {
				this.lazyLoadEvent.filters = this.lazyLoadEvent.filters ?? {};
				// Only RADIO_BUTTON, SINGLE_SELECT, MULTI_SELECT and DATE_SELECT
				// types need this handling process
				switch (column.filterType) {
					case FilterType.RADIO_BUTTON:
					this.lazyLoadEvent.filters[column.filterDef].value = column.filterValueSelection[column.filterValueField ?? ''];
					break;

					case FilterType.SINGLE_SELECT:
					this.lazyLoadEvent.filters[column.filterDef].value = column.filterValueSelection[column.filterValueField ?? ''];
					break;

					case FilterType.MULTI_SELECT:
					this.lazyLoadEvent.filters[column.filterDef].value = column.filterValueSelection.map((select: any) => select[column.filterValueField ?? '']).join(',');
					break;

					case FilterType.DATE_SELECT:
					this.lazyLoadEvent.filters[column.filterDef].value = new Date(column.filterValueSelection).toISOString();
					break;
				}
			}
		}
	}
}
