// Modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, Params } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
// Services
import { MessageService } from 'src/app/shared/services/message.service';
// Structs
import { SnackBarType } from 'src/app/shared/structs/snack-bar-type.struct';

@Injectable({
	providedIn: 'root'
})
export class HttpHelperService {
	url = 'Base';

	constructor(
		private http: HttpClient,
		private router: Router,
		private messageService: MessageService) {
	}

	GET(url: string): Observable<any> {
		return this.http.get<any>(url).pipe(
			catchError(this.handleError<any>())
			);
	}

	PUT(url: string, params: Params): Observable<any> {
		return this.http.put<any>(url, params).pipe(
			catchError(this.handleError<any>())
			);
	}

	POST(url: string, params: Params): Observable<any> {
		return this.http.post(url, params).pipe(
			catchError(this.handleError<any>())
			);
	}

	DELETE(url: string): Observable<any> {
		return this.http.delete<any>(url).pipe(
			catchError(this.handleError<any>())
			);
	}

	DOWNLOAD(url: string, params: Params): Observable<any> {
		return this.http.post(url, params, {
			observe: 'response',
			responseType: 'arraybuffer'
		}).pipe(
		catchError(this.handleError<any>())
		);
	}

	private handleError<T>(result?: T): (error: any) => Observable<T> {
		return (error: any): Observable<T> => {
			let message;
			switch (error.status) {
				case 0:
				message = 'ERROR.TIMEOUT';
				break;

				case 401:
				message = 'ERROR.UNAUTHORIZED';
				localStorage.clear();
				this.router.navigate(['login']);
				break;

				case 404:
				message = 'ERROR.NOT_FOUND';
				break;

				case 500:
				message = 'ERROR.INTERNAL_ERROR';
				break;

				case 200:
				message = error.error.text;
				break;

				default:
				message = 'ERROR.UNKNOWN_ERROR';
				break;
			}
			this.messageService.openSnackBar(message, SnackBarType.ERROR);
			const newResult: any = {};
			// tslint:disable-next-line:no-string-literal
			newResult['status'] = error.status;
			// tslint:disable-next-line:no-string-literal
			newResult['msg'] = message;
			// Let the app keep running by returning an empty result.
			return of(newResult as T);
		};
	}

	// 常用CRUD
	// 新增
	add(params: Params): Observable<any> {
		return this.POST(this.url, params);
	}

	// 删除
	delete(id: string | number): Observable<any> {
		return this.DELETE(`${this.url}/${id}`);
	}

	// 修改
	update(params: Params): Observable<any> {
		return this.PUT(this.url, params);
	}

	// 列表查询
	list(): Observable<any> {
		return this.GET(`${this.url}/list`);
	}

	// 详情查询
	detail(id: string | number): Observable<any> {
		return this.GET(`${this.url}/${id}`);
	}
}

