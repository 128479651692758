<div class="counter-container">
	<textarea pInputTextarea
		class="counter-area"
		[(ngModel)]="textValue"
		[placeholder]="placeholder"
		[maxLength]="maxLimit ? maxLimit : 'unset'"
		[style.height.rem]="remHeight"
		[disabled]="disabled"
		(change)="onValueChange($event)"
		(blur)="onTouched()"
		[class]="textareaStyleClass ? textareaStyleClass : null">
	</textarea>
	<span *ngIf="showCounter" class="counter-text">{{textValue ? textValue.length : 0}}<span *ngIf="maxLimit">/{{maxLimit}}</span></span>
</div>