// Modules
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
// Services
import { TranslateService } from '@ngx-translate/core';
// Structs
import { BasicConfig } from 'src/app/shared/basic-config';
import { SnackBarType } from 'src/app/shared/structs/snack-bar-type.struct';
// Components
import { DeleteConfirmComponent } from 'src/app/shared/components/delete-confirm/delete-confirm.component';

@Injectable({
	providedIn: 'root'
})
export class MessageService {

	// 设置调用接口时，显示遮罩层
	private showMask = new Subject<any>();
	showMaskObservable = this.showMask.asObservable();

	constructor(
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
		private dialog: MatDialog) { }

	// 接口成功/失败提示信息
	openSnackBar(message: string, type?: number, action?: any, duration?: number): void {
		message = this.translateService.instant(message);
		action = action ? this.translateService.instant(action) : '🗙';
		this.snackBar.open(message, action, {
			duration: duration ?? BasicConfig.duration, // 持续时间
			verticalPosition: 'top', // 垂直位置
			panelClass: type === SnackBarType.ERROR ? 'error-snack' : 'success-snack'
		});
	}

	// 确认是否删除
	confirmDelete(): boolean {
		let confirmDelete = false;
		const dialogRef = this.dialog.open(DeleteConfirmComponent);

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				confirmDelete = true;
			}
		});
		return confirmDelete;
	}

	// 修改showMask的值
	broadcastShowMask(show: boolean): void {
		this.showMask.next(show);
	}

	// 上传附件点击
	pdfjsViewer(file: any): void {
		if (file) {
			let fileName = file.name.lastIndexOf('.');// 取到文件名开始到最后一个点的长度
			let fileNameLength = file.name.length;// 取到文件名长度
			let fileType = file.name.substring(fileName + 1, fileNameLength);// 截
			if (fileType === 'pdf') {
				localStorage.setItem('filePath', file.path ? file.path : file.url);
				window.open('#/pdfjs/viewer', '_blank');
			} else {
				window.open(file.path ? file.path : file.url, '_blank');
			}
		}
	}
}
