// Modules
import { Component, Input, Optional, Self, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, Validator, NgControl, AbstractControl, ValidationErrors } from '@angular/forms';

@Component({
	selector: 'counter-textarea',
	templateUrl: './counter-textarea.component.html',
	styleUrls: ['./counter-textarea.component.scss']
})
export class CounterTextareaComponent implements ControlValueAccessor, Validator {
	// Property
	@Input() placeholder: string = '';
	@Input() showCounter: boolean = true;
	@Input() remHeight: number = 10;
	@Input() maxLimit: number = 0;
	@Input() disabled: boolean = false;
	// Data Binding
	@Input() textValue: string = '';
	// Styling
	@Input() textareaStyleClass: string = '';

	onChange: any = (value: any) => {};
	onTouched: any = () => {};
	
	constructor(
		@Optional() @Self() public ngControl: NgControl,
		private changeDetector: ChangeDetectorRef
		) {
		if (ngControl !== null) {
			// Setting the value accessor directly (instead of using
			// the providers) to avoid running into a circular import.
			ngControl.valueAccessor = this;
		}
	}

	onValueChange(event: any): void {
		this.onChange(event.target.value);
	}

	writeValue(value: string): void {
		this.textValue = value;
		this.changeDetector.markForCheck();
	}

	registerOnChange(onChange: (value: any) => void): void {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: () => void): void {
		this.onTouched = onTouched;
	}

	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
		this.changeDetector.markForCheck();
	}

	validate(control: AbstractControl): ValidationErrors | null {
		const value: any = control.value;
		return null;
	}
}