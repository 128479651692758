export enum ActionType {
	ADD = 0,
	VIEW = 1,
	EDIT = 2,
	DELETE = 3,
	MULTI_DELETE = 4,
	EXPORT = 5,
	DISABLE = 6,
	START = 7,
	END = 8,
	STOP = 9,
	PAUSE = 10,
	IMPORT = 11,
	REFUND = 12,
	ACTIVATE = 13,
	DEACTIVATE = 14
}