// Modules
import { Component, Input, OnInit, OnChanges, SimpleChanges, Optional, Self, ChangeDetectorRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, Validator, NgControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { Dropdown } from 'primeng/dropdown';
import { MultiSelect } from 'primeng/multiselect';

@Component({
	selector: 'dropdown-extra',
	templateUrl: './dropdown-extra.component.html',
	styleUrls: ['./dropdown-extra.component.scss']
})
export class DropdownExtraComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
	// Property
	@Input() options: any[] = [];
	@Input() extraOptions: any[] = [];
	@Input() placeholder: string = '';
	@Input() optionLabel: string = '';
	@Input() optionValue: string = '';
	@Input() disabled: boolean = false;
	@Input() virtualScroll: boolean = true;
	@Input() itemSize: number = 30;
	@Input() dropdownIcon: string = 'pi pi-chevron-down';
	@Input() showClear: boolean = true;
	@Input() multiSelect: boolean = false;
	@Input() alignment: string = 'row';
	@Input() position: string = 'space-between';
	@Input() width: number = 100;
	// Data Binding
	@Input() selectedValue: any = null;
	// Styling
	@Input() dropdownStyleClass: string = '';
	// Event
	@Output() extraOptionEvent: EventEmitter<any> = new EventEmitter<any>();
	@Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();
	// Component Reference
	@ViewChild('dropdownComponent') dropdownComponent!: Dropdown;
	@ViewChild('multiSelectComponent') multiSelectComponent!: MultiSelect;

	combinedOptions: any[] = [];

	onChange: any = (value: any) => {};
	onTouched: any = () => {};

	constructor(
		@Optional() @Self() public ngControl: NgControl,
		private changeDetector: ChangeDetectorRef
		) {
		if (ngControl !== null) {
			// Setting the value accessor directly (instead of using
			// the providers) to avoid running into a circular import.
			ngControl.valueAccessor = this;
		}
	}

	ngOnInit(): void {
		this.combinedOptions = this.options.concat(this.extraOptions);
	}

	ngOnChanges(changes: SimpleChanges): void {
		Object.keys(changes).forEach(key => {
			switch (key) {
				case 'options':
				case 'extraOptions':
				this.combinedOptions = this.options.concat(this.extraOptions);
				break;
			}
		});
	}

	extraOptionSelected(): boolean {
		if (this.multiSelect) {
			const extraOptionValues: any[] = this.extraOptions.map(option => option[this.optionValue]);
			for (const value of this.selectedValue) {
				if (extraOptionValues.indexOf(value) !== -1) {
					return true;
				}
			}
			return false;
		} else {
			return this.extraOptions.filter(option => option[this.optionValue] === this.selectedValue).length > 0;
		}
	}

	onValueFilter(event: any): void {
		this.filterEvent.emit(event.filter);
	}

	onValueChange(event: any): void {
		this.onChange(event.value);
		if (this.extraOptionSelected()) {
			if (this.multiSelect) {
				let emitValue: any[] = [];
				const extraOptionValues: any[] = this.extraOptions.map(option => option[this.optionValue]);
				for (const value of this.selectedValue) {
					if (extraOptionValues.indexOf(value) !== -1) {
						emitValue.push(value);
					}
				}
				this.extraOptionEvent.emit(emitValue);
			} else {
				this.extraOptionEvent.emit(this.selectedValue);
			}
		}
	}

	writeValue(value: any): void {
		this.selectedValue = value;
		this.changeDetector.markForCheck();
	}

	registerOnChange(onChange: (value: any) => void): void {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: () => void): void {
		this.onTouched = onTouched;
	}

	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
		this.changeDetector.markForCheck();
	}

	validate(control: AbstractControl): ValidationErrors | null {
		const value: any = control.value;
		return null;
	}
}
