// Modules
import { Pipe, PipeTransform } from '@angular/core';
// Services
import { SystemCodeService } from 'src/app/shared/services/system-code.service';

@Pipe({
	name: 'translateCode'
})
export class TranslateCodePipe implements PipeTransform {

	constructor(private systemCodeService: SystemCodeService) { }

	transform(value: any, ...args: any[]): any {
		return this.systemCodeService.translate(value);
	}

}
