// Modules
import { Injectable } from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpResponse, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, timeout } from 'rxjs/operators';
// Structs
import { BasicConfig } from 'src/app/shared/basic-config';
import { environment } from 'src/environments/environment';
// Services
import { MessageService } from 'src/app/shared/services/message.service';

@Injectable()
export class InterceptService implements HttpInterceptor {
	apiPrefix = '/api/';

	constructor(
		private messageService: MessageService
		) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpResponse<any>> {
		let request: any;

		// 调用接口后显示遮罩层
		this.messageService.broadcastShowMask(true);

		if (req.url.indexOf('i18n') === -1) {
			if (BasicConfig.noTokenUrl.filter(res => req.url.indexOf(res) !== -1).length === 0) { // 不在白名单内的请求需要传token
				request = {
					url: environment.url + this.apiPrefix + req.url,
					headers: req.headers.set('Authorization', 'Bearer ' + `${localStorage.getItem('token')}`)
				};
			} else {
				request = {
					url: environment.url + this.apiPrefix + req.url,
				};
			}
		}

		const jwtReq = req.clone(request).clone({
			withCredentials: true
		});

		return next.handle(jwtReq)
		.pipe(
			timeout(BasicConfig.timeout), // 接口请求时间限制，超过设置时间认为超时并结束请求
			tap((event: any) => {
				// 接口返回数据后隐藏遮罩层
				if (event.status === 200) {
					this.messageService.broadcastShowMask(false);
				}
			}, (err: any) => {
				if (err.name === 'TimeoutError') {
					err.status = 0;
				}
				// 接口返回数据后隐藏遮罩层
				this.messageService.broadcastShowMask(false);
				return err;
			})
		);
	}
}