<div class="login">
	<img class="logo" src="assets/login/login_logo.svg"/>
	<div class="login-section">
		<form class="container form" [formGroup]="form" (ngSubmit)="login()">
			<label>{{'LABEL_USERNAME' | translate}}</label>
			<input placeholder="{{'LABEL_USERNAME' | translate}}" formControlName="username" />
			<label>{{'LABEL_PASSWORD' | translate}}</label>
			<input type="password" placeholder="{{'LABEL_PASSWORD' | translate}}" formControlName="password" />
			<div class="login-button">
				<button mat-button class="confirm" type="submit">{{'SYSTEM.LOG_IN' | translate}}</button>
			</div>
		</form>
	</div>
</div>