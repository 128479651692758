export const CodeMapping: any = {
	active_status: '_SYS_A9',
	inspection_type_status: '_PSFO_ALT_STATUS',
	action_verifier: '_PSFO_ACTION_VERIFIER',
	action_reviewer: '_PSFO_ACTION_REVIEWER',
	inspection_review_title: '_SYS_AJ',
	risk_level: '_PSFO_RISK_LEVEL',
	task_status: '_PSFO_TASK_STATUS',
	task_types: '_PSFO_TASK_TYPE',
	lifecycle: '_PSFO_LIFECYCLE',
	risk_level_list: '_SYS_AX',
	org_type: '_SYS_A8',
	org_type1: '_SYS_A8_0',
	org_type2: '_SYS_A8_1',
	planned_status: '_PSFO_SCHEDULE_STATUS'
};
