// Modules
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// Services
import { MessageService } from 'src/app/shared/services/message.service';
import { LoginService } from 'src/app/shared/services/login.service';
// Structs
import { StatusCode } from 'src/app/shared/structs/status-code.struct';
import { SnackBarType } from 'src/app/shared/structs/snack-bar-type.struct';

@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	form: FormGroup = this.formBuilder.group({
		username: [null, Validators.required],
		password: [null, Validators.required]
	});

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private messageService: MessageService,
		private loginService: LoginService
		) { }

	ngOnInit(): void {
	}

	validationCheck(): boolean {
		if (this.form.invalid) {
			if (this.form.controls.username.errors) {
				switch (Object.keys(this.form.controls.username.errors)[0]) {
					case 'required':
					this.messageService.openSnackBar('ERROR.REQUIRED', SnackBarType.ERROR);
					break;
				}
			} else if (this.form.controls.password.errors) {
				switch (Object.keys(this.form.controls.password.errors)[0]) {
					case 'required':
					this.messageService.openSnackBar('ERROR.REQUIRED', SnackBarType.ERROR);
					break;
				}
			}
			return false;
		} else {
			return true;
		}
	}

	// 登录
	login(): void {
		if (this.validationCheck()) {
			this.loginService.getAuthToken(this.form.controls.username.value, this.form.controls.password.value).subscribe(tokenRes => {
				if (tokenRes && tokenRes.code === StatusCode.OK && tokenRes.data) {
					localStorage.setItem('token', tokenRes.data.token);
					this.loginService.login(this.form.controls.username.value, this.form.controls.password.value).subscribe(loginRes => {
						if (loginRes && loginRes.code === StatusCode.OK) {
							localStorage.setItem('roles', JSON.stringify(loginRes.data.role));
							localStorage.setItem('menu', JSON.stringify(loginRes.data.menu));
							localStorage.setItem('permission', JSON.stringify(loginRes.data.permission.filter((perm: any) => perm.viewAccess === true)));
							localStorage.setItem('username', this.form.controls.username.value);
							this.messageService.openSnackBar('SUCCESS.LOGIN_SUCCESS');
							this.router.navigate(['user-management']);
						} else if (loginRes.code !== StatusCode.OK) {
							this.messageService.openSnackBar(loginRes.msg ?? 'ERROR.UNKNOWN_ERROR', SnackBarType.ERROR);
						} else {
							this.messageService.openSnackBar('ERROR.UNKNOWN_ERROR', SnackBarType.ERROR);
						}
					}, loginErr => {
						this.messageService.openSnackBar('ERROR.UNKNOWN_ERROR', SnackBarType.ERROR);
					});
				} else if (tokenRes.code !== StatusCode.OK || !tokenRes.data) {
					this.messageService.openSnackBar(tokenRes.msg ?? 'ERROR.UNKNOWN_ERROR', SnackBarType.ERROR);
				} else {
					this.messageService.openSnackBar('ERROR.UNKNOWN_ERROR', SnackBarType.ERROR);
				}
			}, tokenErr => {
				this.messageService.openSnackBar('ERROR.UNKNOWN_ERROR', SnackBarType.ERROR);
			});
		}
	}
}
