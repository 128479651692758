<div class="container query-fields" [style.margin-top.px]="pxMarginTop" [style.margin-bottom.px]="pxMarginBottom" [style.margin-top.rem]="remMarginTop" [style.margin-bottom.rem]="remMarginBottom" [style.padding-top.px]="pxPaddingTop" [style.padding-bottom.px]="pxPaddingBottom" [style.padding-top.rem]="remPaddingTop" [style.padding-bottom.rem]="remPaddingBottom">
	<!-- 查询条件 -->
	<ng-container *ngFor="let field of fields; let i = index;">
		<div class="search-form" [style.width.%]="field.width ? field.width : 20" [ngSwitch]="field.type">
			<!-- 输入框 -->
			<div *ngSwitchCase="FilterType.INPUT">
				<input pInputText placeholder="{{ field.placeholder | translate }}" [(ngModel)]="query[field.ngModel]" />
			</div>
			<!-- 单选下拉 -->
			<!-- Each item in the list has a height of 39px and the list needs 7px padding on both top and bottom -->
			<div class="single-select" *ngSwitchCase="FilterType.SINGLE_SELECT">
				<p-dropdown [options]="field.options" [(ngModel)]="query[field.ngModel]" [virtualScroll]="true" itemSize="30" class="screening-dropdown" dropdownIcon="pi pi-chevron-down" [showClear]="true" placeholder="{{ field.placeholder | translate }}" [filter]="field.value" filterBy="{{ field.value }}" scrollHeight="{{ ((field.options.length * 39 + 14) > 200 ? 200 : field.options.length * 39 + 14) + 'px' }}" (onChange)="field.scope && field.callback($event)">
					<ng-template let-select pTemplate="selectedItem">
						<span class="selected-label">{{ (field.value ? select[field.value] : select) | translate | translateCode }}</span>
					</ng-template>
					<ng-template let-option pTemplate="item">
						<div>{{ (field.value ? option[field.value] : option) | translate | translateCode }}</div>
					</ng-template>
				</p-dropdown>
			</div>
			<!-- 多选下拉 -->
			<!-- Each item in the list has a height of 39px and the list needs 7px padding on both top and bottom -->
			<div class="multi-select" *ngSwitchCase="FilterType.MULTI_SELECT">
				<p-multiSelect [options]="field.options" [(ngModel)]="query[field.ngModel]" [virtualScroll]="true" itemSize="30" class="screening-dropdown" dropdownIcon="pi pi-chevron-down" placeholder="{{ field.placeholder | translate }}" [filter]="field.value" filterBy="{{ field.value }}" scrollHeight="{{ ((field.options.length * 39 + 14) > 200 ? 200 : field.options.length * 39 + 14) + 'px' }}" (onChange)="field.scope && field.callback($event)">
					<ng-template let-select pTemplate="selectedItems">
						<span class="selected-label" *ngFor="let selected of query[field.ngModel]; let idx = index;">{{ (field.value ? selected[field.value] : selected) | translate | translateCode }}<span *ngIf="idx !== query[field.ngModel].length - 1">,&nbsp;</span></span>
						<div *ngIf="!query[field.ngModel] || query[field.ngModel].length === 0">
							{{ field.placeholder | translate }}
						</div>
					</ng-template>
					<ng-template let-option pTemplate="item">
						<div>{{ (field.value ? option[field.value] : option) | translate | translateCode }}</div>
					</ng-template>
				</p-multiSelect>
			</div>		
			<!-- 按钮 -->
			<div class="radio-button-select" *ngSwitchCase="FilterType.RADIO_BUTTON">
				<mat-radio-group [(ngModel)]="query[field.ngModel]">
					<mat-radio-button *ngFor="let option of field.options" [value]="field.key ? option[field.key] : option">
						{{ (field.value ? option[field.value] : option) | translate }}
					</mat-radio-button>
				</mat-radio-group>
			</div>
			<!-- 时间 -->
			<div class="multi-select-date" *ngSwitchCase="FilterType.DATE_SELECT">
				<p-calendar placeholder="{{ field.placeholderStart | translate }}" [(ngModel)]="query[field.ngModelStart]" dateFormat="{{field.dateFormat ? field.dateFormat : BasicConfig.calendarDateFormat}}" showButtonBar="true"></p-calendar>
				<p-calendar placeholder="{{ field.placeholderEnd | translate }}" [(ngModel)]="query[field.ngModelEnd]" dateFormat="{{field.dateFormat ? field.dateFormat : BasicConfig.calendarDateFormat}}" showButtonBar="true"></p-calendar>
			</div>
			<!-- 空白 -->
			<div *ngSwitchCase="FilterType.BLANK"></div>
		</div>
	</ng-container>
	<div class="search-button-btn">
		<button type="button" mat-button class="responsive-button btn cancel btn-fixed-width m-r-1rem" (click)="resetSearch()">{{ 'LABEL_RESET' | translate }}</button>
		<button type="button" mat-button class="responsive-button btn confirm btn-fixed-width m-r-1rem" (click)="searchList()">{{ 'LABEL_SEARCH' | translate }}</button>
	</div>
</div>