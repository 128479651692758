// Modules
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Components
import { LoginComponent } from 'src/app/page/login/login.component';
import { SelectFundComponent } from 'src/app/page/external/select-fund/select-fund.component';
// Services
import { CanActiveGuardService } from 'src/app/shared/services/can-active-guard.service';
import { AuthGuardService } from 'src/app/shared/services/auth-guard.service';

const routes: Routes = [
{
	path: 'login',
	component: LoginComponent
},
{
	path: 'select-fund',
	component: SelectFundComponent
},
{
	path: 'user-management',
	loadChildren: () => import('src/app/page/user-management/user-management.module').then(m => m.UserManagementModule),
	canActivate: [AuthGuardService, CanActiveGuardService]
},
{
	path: 'entity-management',
	loadChildren: () => import('src/app/page/entity-management/entity-management.module').then(m => m.EntityManagementModule),
	canActivate: [AuthGuardService, CanActiveGuardService]
},
{
	path: 'fund-management',
	loadChildren: () => import('src/app/page/fund-management/fund-management.module').then(m => m.FundManagementModule),
	canActivate: [AuthGuardService, CanActiveGuardService]
},
{
	path: 'fund-balance-history',
	loadChildren: () => import('src/app/page/fund-balance-history/fund-balance-history.module').then(m => m.FundBalanceHistoryModule),
	canActivate: [AuthGuardService, CanActiveGuardService]
},
{
	path: '',
	redirectTo: 'login',
	pathMatch: 'full'
},
];

@NgModule({
	imports: [
	RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
