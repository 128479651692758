// Modules
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
// Services
import { HttpHelperService } from 'src/app/shared/services/http-helper.service';

@Injectable({
	providedIn: 'root'
})
export class ProductService {

	constructor(
		private http: HttpHelperService
	) { }

	getFundListForNetSuite(): Observable<any> {
		return this.http.GET(`Product/getFundListForNetSuite`);
	}

	getSelectedFunds(params: Params): Observable<any> {
		return this.http.POST(`Product/getFundListByProduct`, params);
	}

	addFundProduct(params: Params): Observable<any> {
		return this.http.POST(`Product/AddFundProduct`, params);
	}

	getProductListByCategory(params: Params): Observable<any> {
		return this.http.POST(`Product/GetProductListByCategory`, params);
	}
}
