// Modules
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
// Services
import { HttpHelperService } from 'src/app/shared/services/http-helper.service';

@Injectable({
	providedIn: 'root'
})
export class UserManagementService {

	constructor(
		private http: HttpHelperService
	) { }

	getUserList(params: Params): Observable<any> {
		return this.http.POST(`User/getUserlist`, params);
	}

	getFilterData(): Observable<any> {
		return this.http.GET(`User/getFilterData`);
	}

	getUserProfile(params: Params): Observable<any> {
		return this.http.POST(`User/getUserProfile`, params);
	}

	updateUser(params: Params): Observable<any> {
		return this.http.POST(`User/UpdateUser`, params);
	}

	addUser(params: Params): Observable<any> {
		return this.http.POST(`User/AddUser`, params);
	}

	exportUserList(params: Params): Observable<any> {
		return this.http.DOWNLOAD(`User/Export`, params);
	}

	changePassword(params: Params): Observable<any> {
		return this.http.POST(`User/ChangePassword`, params);
	}

	getFundsByUser(params: Params): Observable<any> {
		return this.http.POST(`User/GetFundsByUser`, params);
	}

	getAvailableFunds(params: Params): Observable<any> {
		return this.http.POST(`User/getAvailableFunds`, params);
	}

	removeUserAmount(params: Params): Observable<any> {
		return this.http.POST(`User/RemoveUserAmount`, params);
	}

	addFundForUser(params: Params): Observable<any> {
		return this.http.POST(`User/AddFundForUser`, params);
	}

	getDropdownDataInDetail(): Observable<any> {
		return this.http.GET(`User/GetDropdownDataInDetail`);
	}

	getEntityListByUser(params: Params): Observable<any> {
		return this.http.POST(`User/GetEntityListByUser`, params);
	}
}
