<div class="shared-table container" [style.margin-top.px]="pxMarginTop" [style.margin-bottom.px]="pxMarginBottom" [style.margin-top.rem]="remMarginTop" [style.margin-bottom.rem]="remMarginBottom" [style.padding-top.px]="pxPaddingTop" [style.padding-bottom.px]="pxPaddingBottom" [style.padding-top.rem]="remPaddingTop" [style.padding-bottom.rem]="remPaddingBottom">
	<!-- 按钮组 -->
	<div class="flex-btn-group">
		<div class="left-button-group">
			<ng-container *ngFor="let button of leftButtonGroup" [ngSwitch]="button">
				<button mat-button
					*ngSwitchCase="ActionType.MULTI_DELETE"
					(click)="multiRowsAction(button)" 
					class="responsive-button btn confirm fill-btn delete-btn-left btn-fixed-width"
					>{{ 'LABEL_DELETE' | translate}}
				</button>
				<button mat-button
					*ngSwitchCase="ActionType.ADD"
					(click)="multiRowsAction(button)"
					class="responsive-button btn confirm fill-btn add-btn-left btn-fixed-width"
					>{{ 'LABEL_CREATE' | translate}}
				</button>
				<button mat-button
					*ngSwitchCase="ActionType.EXPORT"
					(click)="multiRowsAction(button)"
					class="responsive-button btn export-btn-left"
					>
					<img src="assets/imgs/export.svg" />
					<span>{{ 'LABEL_EXPORT' | translate}}</span>
				</button>
				<button mat-button
					*ngSwitchCase="ActionType.REFUND"
					(click)="multiRowsAction(button)"
					class="responsive-button btn confirm fill-btn refund-btn-left btn-fixed-width"
					>{{ 'LABEL_REFUND' | translate}}
				</button>
				<button mat-button
					*ngSwitchCase="ActionType.IMPORT"
					(click)="uploaderLeft.click()"
					class="responsive-button btn confirm fill-btn add-btn-left btn-fixed-width"
					>
					<input hidden
						type="file"
						#uploaderLeft
						onclick="this.value = null;"
						(change)="multiRowsAction(button, $event)"
						accept=".xls,.xlsx,.csv,.xml"
					/>
					<span>{{ 'LABEL_IMPORT' | translate}}</span>
				</button>
			</ng-container>
			<ng-container *ngFor="let button of leftCustomButtonGroup" [ngSwitch]="button.type">
				<button mat-button *ngSwitchCase="'FILE'"
					(click)="uploaderLeftCustom.click()" 
					class="responsive-button btn confirm fill-btn add-btn-left"
					[class.btn-fixed-width]="button.fixedWidth"
					>
					<input hidden
						type="file"
						#uploaderLeftCustom
						onclick="this.value = null;"
						(change)="multiRowsCustomAction(button, $event)"
						accept=".xls,.xlsx,.csv,.xml"
					/>
					<span>{{ button.title | translate}}</span>
				</button>
				<button mat-button *ngSwitchDefault
					(click)="multiRowsCustomAction(button)" 
					class="responsive-button btn confirm fill-btn add-btn-left"
					[class.btn-fixed-width]="button.fixedWidth"
					>
					<mat-icon class="button-mat-icon" *ngIf="button.icon">{{ button.icon | translate }}</mat-icon>
					<span>{{ button.title | translate}}</span>
				</button>
			</ng-container>
		</div>
		<div class="right-button-group">
			<ng-container *ngFor="let button of rightButtonGroup" [ngSwitch]="button">
				<button mat-button
					*ngSwitchCase="ActionType.MULTI_DELETE"
					(click)="multiRowsAction(button)"
					class="responsive-button btn confirm fill-btn delete-btn-right btn-fixed-width"
					>{{ 'LABEL_DELETE' | translate}}
				</button>
				<button mat-button
					*ngSwitchCase="ActionType.ADD"
					(click)="multiRowsAction(button)"
					class="responsive-button btn confirm fill-btn add-btn-right btn-fixed-width"
					>{{ 'LABEL_CREATE' | translate}}
				</button>
				<button mat-button
					*ngSwitchCase="ActionType.EXPORT"
					(click)="multiRowsAction(button)"
					class="responsive-button btn export-btn-right"
					>
					<img src="assets/imgs/export.svg" />
					<span>{{ 'LABEL_EXPORT' | translate}}</span>
				</button>
				<button mat-button
					*ngSwitchCase="ActionType.REFUND"
					(click)="multiRowsAction(button)"
					class="responsive-button btn confirm fill-btn refund-btn-right btn-fixed-width"
					>{{ 'LABEL_REFUND' | translate}}
				</button>
				<button mat-button
					*ngSwitchCase="ActionType.IMPORT"
					(click)="uploaderRight.click()"
					class="responsive-button btn confirm fill-btn add-btn-right btn-fixed-width"
					>
					<input hidden
						type="file"
						#uploaderRight
						onclick="this.value = null;"
						(change)="multiRowsAction(button, $event)"
						accept=".xls,.xlsx,.csv,.xml"
					/>
					<span>{{ 'LABEL_IMPORT' | translate}}</span>
				</button>
			</ng-container>
			<ng-container *ngFor="let button of rightCustomButtonGroup" [ngSwitch]="button.type">
				<button mat-button *ngSwitchCase="'FILE'"
					(click)="uploaderRightCustom.click()" 
					class="responsive-button btn confirm fill-btn add-btn-right"
					[class.btn-fixed-width]="button.fixedWidth"
					>
					<input hidden
						type="file"
						#uploaderRightCustom
						onclick="this.value = null;"
						(change)="multiRowsCustomAction(button, $event)"
						accept=".xls,.xlsx,.csv,.xml"
					/>
					<span>{{ button.title | translate}}</span>
				</button>
				<button mat-button *ngSwitchDefault
					(click)="multiRowsCustomAction(button)" 
					class="responsive-button btn confirm fill-btn add-btn-right"
					[class.btn-fixed-width]="button.fixedWidth"
					>
					<mat-icon class="button-mat-icon" *ngIf="button.icon">{{ button.icon | translate }}</mat-icon>
					<span>{{ button.title | translate}}</span>
				</button>
			</ng-container>
		</div>
	</div>

	<p-table #pdt
	[value]="dataSource"
	[dataKey]="uniqueKey"
	[rows]="pageSize"
	[selectionMode]="selectionMode"
	[rowsPerPageOptions]="rowsPerPageOptions"
	[loading]="loading"
	[paginator]="paginator"
	[showCurrentPageReport]="currentPageReport"
	[currentPageReportTemplate]="currentPageReportTemplate"
	(sortFunction)="sort($event)"
	[customSort]="true"
	[(selection)]="selection"
	(onRowSelect)="toggle($event)"
	(onRowUnselect)="toggle($event)"
	(onHeaderCheckboxToggle)="masterToggle($event)"
	[lazy]="true"
	(onLazyLoad)="lazyLoad($event)"
	[totalRecords]="totalRecords"
	>
		<!-- 表头 -->
		<ng-template pTemplate="header">
			<tr class="top-header">
				<ng-container *ngFor="let column of columns;">
					<th *ngIf="column.noSort && column.type !== 'checkbox'" [style.width.%]="column.width ? column.width : 'auto'">{{ column.header | translate }}</th>
					<th *ngIf="column.noSort && column.type === 'checkbox'" class="checkbox-width" [style.width.%]="column.width ? column.width : 'auto'">
						<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
					</th>
					<th *ngIf="!column.noSort" [pSortableColumn]="column.sortDef" [style.width.%]="column.width ? column.width : 'auto'">{{ column.header | translate }}&nbsp;<p-sortIcon [field]="column.sortDef"></p-sortIcon></th>
				</ng-container>
			</tr>
			<tr *ngIf="columnFilter" class="filter-header">
				<!-- 表格内筛选 -->
				<th *ngFor="let column of columns;" [ngSwitch]="column.filterType">
					<!-- 输入框 -->
					<p-columnFilter *ngSwitchCase="FilterType.INPUT" type="text" [field]="column.filterDef" [showMenu]="column.customFiltering" matchMode="{{column.matchMode ? column.matchMode : 'null'}}" placeholder="{{ column.header | translate }}"></p-columnFilter>
					<!-- 数值输入 -->
					<p-columnFilter *ngSwitchCase="FilterType.NUMBER" type="numeric" [field]="column.filterDef" [showMenu]="column.customFiltering" matchMode="{{column.matchMode ? column.matchMode : 'null'}}" placeholder="{{ column.header | translate }}"></p-columnFilter>
					<!-- 下拉单选 -->
					<p-columnFilter *ngSwitchCase="FilterType.SINGLE_SELECT" [field]="column.filterDef" [showMenu]="column.customFiltering" matchMode="{{column.matchMode ? column.matchMode : 'null'}}">
						<ng-template pTemplate="filter" let-value let-filter="filterCallback">
							<p-dropdown [(ngModel)]="column.filterValueSelection" [options]="column.filterOptions" placeholder="{{ column.header | translate }}" [virtualScroll]="true" itemSize="30" class="screening-dropdown" [filter]="column.filterDisplayField" filterBy="{{ column.filterDisplayField }}" (onChange)="lazyLoad($event)">
								<ng-template let-select pTemplate="selectedItem">
									<span class="selected-label">{{ (column.filterDisplayField ? select[column.filterDisplayField] : select) | translate | translateCode }}</span>
								</ng-template>
								<ng-template let-option pTemplate="item">
									<div class="p-multiselect-representative-option">
										<span class="p-ml-1">{{ option[column.filterDisplayField] | translate | translateCode }}</span>
									</div>
								</ng-template>
							</p-dropdown>
						</ng-template>
					</p-columnFilter>
					<!-- 下拉多选 -->
					<p-columnFilter *ngSwitchCase="FilterType.MULTI_SELECT" [field]="column.filterDef" [showMenu]="column.customFiltering" matchMode="{{column.matchMode ? column.matchMode : 'null'}}">
						<ng-template pTemplate="filter" let-value let-filter="filterCallback">
							<p-multiSelect [(ngModel)]="column.filterValueSelection" [options]="column.filterOptions" [virtualScroll]="true" itemSize="30" placeholder="{{ column.header | translate }}" class="screening-dropdown" [filter]="column.filterDisplayField" filterBy="{{ column.filterDisplayField }}" (onChange)="lazyLoad($event)" scrollHeight="{{ ((column.filterOptions.length * 39 + 14) > 200 ? 200 : column.filterOptions.length * 39 + 14) + 'px' }}">
								<ng-template let-select pTemplate="selectedItems">
									<span class="selected-label" *ngFor="let selected of column.filterValueSelection; let idx = index;">{{ (column.filterDisplayField ? selected[column.filterDisplayField] : selected) | translate | translateCode }}<span *ngIf="idx !== column.filterValueSelection.length - 1">,&nbsp;</span></span>
									<div *ngIf="!column.filterValueSelection || column.filterValueSelection.length === 0">
										{{ column.header | translate }}
									</div>
								</ng-template>
								<ng-template let-option pTemplate="item">
									<div class="p-multiselect-representative-option">
										<span class="p-ml-1">{{ (column.filterDisplayField ? option[column.filterDisplayField] : option) | translate | translateCode }}</span>
									</div>
								</ng-template>
							</p-multiSelect>
						</ng-template>
					</p-columnFilter>
					<!-- 按钮单选 -->
					<p-columnFilter *ngSwitchCase="FilterType.RADIO_BUTTON" [field]="column.filterDef" [showMenu]="column.customFiltering" matchMode="{{column.matchMode ? column.matchMode : 'null'}}">
						<ng-template pTemplate="filter" let-value let-filter="filterCallback">
							<p-dropdown [(ngModel)]="column.filterValueSelection" [options]="column.filterOptions" placeholder="{{ column.header | translate }}" [virtualScroll]="true" itemSize="30" class="screening-dropdown" [filter]="column.filterDisplayField" filterBy="{{ column.filterDisplayField }}" (onChange)="lazyLoad($event)">
								<ng-template let-select pTemplate="selectedItem">
									<span class="selected-label">{{ (column.filterDisplayField ? select[column.filterDisplayField] : select) | translate | translateCode }}</span>
								</ng-template>
								<ng-template let-option pTemplate="item">
									<div class="p-multiselect-representative-option">
										<span class="p-ml-1">{{ option[column.filterDisplayField] | translate | translateCode }}</span>
									</div>
								</ng-template>
							</p-dropdown>
						</ng-template>
					</p-columnFilter>
					<!-- 布尔单选 -->
					<p-columnFilter *ngSwitchCase="FilterType.BOOLEAN" type="boolean" [field]="column.filterDef" [showMenu]="column.customFiltering" matchMode="{{column.matchMode ? column.matchMode : 'null'}}"></p-columnFilter>
					<!-- 日期选择 -->
					<p-columnFilter *ngSwitchCase="FilterType.DATE_SELECT" type="date" [field]="column.filterDef" [showMenu]="column.customFiltering" matchMode="{{column.matchMode ? column.matchMode : 'null'}}">
						<ng-template pTemplate="filter" let-value let-filter="filterCallback">
							<p-calendar [(ngModel)]="column.filterValueSelection" class="filter-datepicker" placeholder="{{ column.header | translate }}" (onSelect)="lazyLoad($event)" dateFormat="{{column.calendarDateFormat ? column.calendarDateFormat : BasicConfig.calendarDateFormat}}" showButtonBar="true"></p-calendar>
						</ng-template>
					</p-columnFilter>
				</th>
			</tr>
		</ng-template>
		<!-- 表格主体 -->
		<ng-template pTemplate="body" let-item>
			<tr>
				<td *ngFor="let column of columns;" [ngSwitch]="column.type">
					<!-- 复选框 -->
					<p-tableCheckbox *ngSwitchCase="'checkbox'" [value]="item"></p-tableCheckbox>
					<!-- 文本 -->
					<span *ngSwitchCase="'text'">{{ item[column.columnDef] | translate }}</span>
					<!-- code -->
					<span *ngSwitchCase="'code'">{{ item[column.columnDef] | translate | translateCode }}</span>
					<!-- 时间 -->
					<span *ngSwitchCase="'date'">{{ item[column.columnDef] | date: 'yyyy-MM-dd' }}</span>
					<span *ngSwitchCase="'date-time'">{{ item[column.columnDef] | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
					<span *ngSwitchCase="'custom-date'">{{ item[column.columnDef] | date: column.dateFormat }}</span>
					<!-- 操作栏 -->
					<ng-container *ngSwitchCase="'action'">
						<div class="action action-row">
							<mat-icon *ngIf="canShowAction(ActionType.VIEW) && !item.hiddenView" title="{{'SYSTEM.VIEW' | translate}}" aria-hidden="false" (click)="view(item)">remove_red_eye</mat-icon>
							<mat-icon *ngIf="canShowAction(ActionType.EDIT) && !item.hiddenEdit" title="{{'SYSTEM.EDIT' | translate}}" aria-hidden="false" (click)="edit(item)">edit</mat-icon>
							<mat-icon *ngIf="canShowAction(ActionType.DELETE) && !item.hiddenDelete" title="{{'SYSTEM.DELETE' | translate}}" aria-hidden="false" (click)="delete(item)">delete</mat-icon>
							<mat-icon *ngIf="canShowAction(ActionType.DISABLE) && !item.hiddenDisabled" title="{{'SYSTEM.DISABLE' | translate}}" aria-hidden="false" (click)="disable(item)">block</mat-icon>

							<!-- 计划性检查 -->
							<mat-icon *ngIf="canShowAction(ActionType.START) && !item.hiddenStart" title="{{'LABEL_START' | translate}}" aria-hidden="false" (click)="start(item)">play_arrow</mat-icon>
							<mat-icon *ngIf="canShowAction(ActionType.END) && !item.hiddenEnd" title="{{'LABEL_END' | translate}}" aria-hidden="false" (click)="end(item)">clear</mat-icon>
							<mat-icon *ngIf="canShowAction(ActionType.STOP) && !item.hiddenStop" title="{{'LABEL_STOP' | translate}}" aria-hidden="false" (click)="stop(item)">stop</mat-icon>
							<mat-icon *ngIf="canShowAction(ActionType.PAUSE) && !item.hiddenPause" title="{{'LABEL_PAUSE' | translate}}" aria-hidden="false" (click)="pause(item)">pause</mat-icon>

							<!-- 预定义操作 -->
							<ng-container *ngFor="let action of rowActions; let i = index;">
								<mat-icon *ngIf="!action.actionDisabled(item)" title="{{action.title | translate}}" [style.color]="action.color ? action.color : '#000000'" aria-hidden="false" (click)="performAction(action.action, item)">{{action.icon}}</mat-icon>
							</ng-container>

							<!-- 自定义操作 -->
							<ng-container *ngFor="let action of customActions; let i = index;">
								<mat-icon *ngIf="!action.actionDisabled(item)" title="{{action.title | translate}}" [style.color]="action.color ? action.color : '#000000'" aria-hidden="false" (click)="action.callback(item)">{{action.icon}}</mat-icon>
							</ng-container>
						</div>
					</ng-container>
				</td>
			</tr>
		</ng-template>
		<!-- 空表格提示信息 -->
		<ng-template pTemplate="emptymessage">
			<tr>
				<td [attr.colspan]="columns.length">{{emptyMessage | translate}}</td>
			</tr>
		</ng-template>
	</p-table>
	<span *ngIf="customPageReport" class="custom-report" [style.right]="pageReportRightPosition ? pageReportRightPosition : 'pxToRem(52px)'">{{customPageReportMessage}}</span>
</div>