<div class="external-content-style" *ngIf="authorized">
	<div class="page-title">
		<div class="title">{{ 'EXTERNAL.SELECT_FUND' | translate }}</div>
	</div>

	<form [formGroup]="fundForm" #ngFundForm="ngForm" (ngSubmit)="submit(ngFundForm)">
		<div class="field-section">
			<div class="field-row">
				<!-- Fund IDs -->
				<div class="field-display" [style.width.%]="40">
					<div class="field-title required">{{ 'ENTITY_MANAGEMENT.FIELD.FUND_IDS' | translate }}</div>
					<div class="field-content">
						<p-multiSelect [options]="fundList" class="screening-dropdown" formControlName="funds" optionLabel="fundName" optionValue="fundId" [virtualScroll]="true" itemSize="30" dropdownIcon="pi pi-chevron-down" placeholder="{{ 'ENTITY_MANAGEMENT.FIELD.FUND_IDS' | translate }}" [filter]="true" filterBy="fundName" scrollHeight="{{ ((fundList.length * 39 + 14) > 200 ? 200 : fundList.length * 39 + 14) + 'px' }}" [class.dropdown-error-border]="ngFundForm.form.controls['funds'].errors && (ngFundForm.form.controls['funds'].touched || ngFundForm.submitted)">
							<ng-template let-option pTemplate="item">
								<div>{{ option.fundName | translate | translateCode }}</div>
							</ng-template>
						</p-multiSelect>
					</div>
				</div>
			</div>
		</div>

		<div class="button-section">
			<button type="submit" mat-button class="responsive-button btn confirm btn-fixed-width m-r-1rem">{{ 'LABEL_SUBMIT' | translate }}</button>
			<button type="button" mat-button class="responsive-button btn cancel btn-fixed-width m-r-1rem" (click)="back()">{{ 'LABEL_CANCEL' | translate }}</button>
		</div>
	</form>
</div>
