// Modules
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// Structs
import { CodeMapping } from 'src/app/shared/code-mapping';
// Services
import { HttpHelperService } from 'src/app/shared/services/http-helper.service';

@Injectable({
	providedIn: 'root'
})
export class SystemCodeService {

	systemCode = new Map(); // 系统代码存储

	constructor(
		private http: HttpHelperService,
		private translateService: TranslateService
		) { }

	// 获取初始化code
	getInitCode(codes: any[]): Promise<any> {
		let code = '';
		let codeName: any[] = [];
		codes.forEach((element, index) => {
			for (const key in CodeMapping) {
				if (CodeMapping[key] === element) {
					codeName.push(key);
				}
			}
			codeName = codeName ? codeName.sort() : codeName;
			if (!this.systemCode.get(codeName[index])) {
				code += element + ',';
			}
		});

		const promise = new Promise((resolve, reject) => {
			if (code) {
				this.http.GET(`codes/childrenOf?codes=${code}`).subscribe(res => {
					if (res.STATUS === 0) {
						res.DATA.forEach((element: any, index: number) => {
							this.systemCode.set(codeName[index], element.childCodes);
						});
						resolve(null);
					} else {
						reject();
					}
				}, err => {
					reject();
				});
			} else {
				resolve(null);
			}
		});
		return promise;
	}

	// 根据父code获取子code
	getChildrenOf(codeName: any[], code: string): void {
		this.http.GET(`codes/childrenOf?codes=${code}`).subscribe(res => {
			if (res.STATUS === 0) {
				res.DATA.forEach((element: any, index: number) => {
					this.systemCode.set(codeName[index], element.childCodes);
				});
			}
		});
	}

	getChildren(code: string) :any {
		return this.http.GET(`codes/childrenOf?codes=${code}`);
	}

	// 翻译
	translate(code: string): string {
		const codes: any[] = [];
		this.systemCode.forEach(res => {
			codes.push(...res);
		});
		if (codes.filter(res => res.code === code)[0]) {
			if (this.translateService.currentLang == 'zh'){
				return codes.filter(res => res.code === code)[0].code_desc_zh;
			} else {
				return codes.filter(res => res.code === code)[0].code_desc_en;
			}
		} else {
			return code;
		}
	}
}
