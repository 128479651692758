// Modules
import { Component, OnInit, Input, ViewChild, AfterViewInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
// Services
import { TranslateService } from '@ngx-translate/core';
import { HttpHelperService } from 'src/app/shared/services/http-helper.service';
// Structs
import { ActionType } from 'src/app/shared/structs/action-type.struct';
import { FilterType } from 'src/app/shared/structs/filter-type.struct';

@Component({
	selector: 'table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
	animations: [
	trigger('detailExpand', [
		state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
		state('expanded', style({ height: '*' })),
		transition('expanded <=> collapsed', animate('50ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})
export class TableComponent implements OnInit, AfterViewInit {
	// Store a reference to the enum
	ActionType = ActionType;
	FilterType = FilterType;

	@Input() dataSource: MatTableDataSource<any> = new MatTableDataSource();
	@Input() columns: any[] = []; // 列数据
	@Input() displayedColumns: any[] = []; // 列名
	@Input() totalCount: number = 0; // 总数
	@Input() pageSize: number = 0;
	@Input() pageIndex: number = 0;
	@Input() leftButtonGroup: Array<ActionType> = new Array(); // 列表左侧头部按钮
	@Input() rightButtonGroup: Array<ActionType> = new Array(); // 列表右侧头部按钮
	@Input() canExpand = false; // 是否有折叠功能
	@Input() hasFoot = true; // 是否有底部分页
	@Input() columnFilter = false; // 是否支持列内筛选
	@Input() actions: ActionType[] = []; // 操作栏
	@Input() pxMarginTop: number = 0;
	@Input() pxMarginBottom: number = 0;
	@Input() remMarginTop: number = 0;
	@Input() remMarginBottom: number = 0;

	@Output() sortResult: EventEmitter<any> = new EventEmitter<any>(); // 排序
	@Output() deleteResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() disabledResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() auditResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() auditResultDiff: EventEmitter<any> = new EventEmitter<any>();
	@Output() pageResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() viewResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() editResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() tableButtonResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() viewChildResult: EventEmitter<any> = new EventEmitter<any>(); // 查看子项

	// 计划性检查
	@Output() startResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() endResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() stopResult: EventEmitter<any> = new EventEmitter<any>();
	@Output() pauseResult: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('paginator') paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), this.changeDetectorRef);

	selection = new SelectionModel<any>(true, []); // checkbox选择
	expandedElement: any; // 折叠层

	constructor(
		private translateService: TranslateService,
		private router: Router,
		private http: HttpHelperService,
		private changeDetectorRef: ChangeDetectorRef
		) {
		this.translateService.onLangChange.subscribe(res => {
			this.ngAfterViewInit();
		});
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
		// 分页插件改为中文
		if (this.paginator) {
			if (this.translateService.currentLang === 'en') {
				this.paginator._intl.itemsPerPageLabel = 'Items per page: ';
				this.paginator._intl.getRangeLabel = (page, pageSize, length) => {
					if (length === 0 || pageSize === 0) {
						return '0 of ' + length + ' in total';
					}
					length = Math.max(length, 0);
					const startIndex = page * pageSize;
					const endIndex = startIndex < length ?
					Math.min(startIndex + pageSize, length) :
					startIndex + pageSize;
					return (startIndex + 1) + '-' + endIndex + ' of ' + length + ' in total';
				};
				this.paginator._intl.firstPageLabel = 'First Page';
				this.paginator._intl.lastPageLabel = 'Last Page';
				this.paginator._intl.nextPageLabel = 'Next';
				this.paginator._intl.previousPageLabel = 'Previous';
			} else {
				this.paginator._intl.itemsPerPageLabel = '每页数据条数：';
				this.paginator._intl.getRangeLabel = (page, pageSize, length) => {
					if (length === 0 || pageSize === 0) {
						return length + '中的0条';
					}
					length = Math.max(length, 0);
					const startIndex = page * pageSize;
					const endIndex = startIndex < length ?
					Math.min(startIndex + pageSize, length) :
					startIndex + pageSize;
					return length + '中的第' + (startIndex + 1) + '-' + endIndex + '条';
				};
				this.paginator._intl.firstPageLabel = '第一页';
				this.paginator._intl.lastPageLabel = '最后一页';
				this.paginator._intl.nextPageLabel = '下一页';
				this.paginator._intl.previousPageLabel = '上一页';
			}
			this.pageSize = JSON.parse(JSON.stringify(this.pageSize));
		}
	}

	multiRowsAction(type: ActionType): void {
		const selectedRows = this.dataSource.data.filter(row => this.selection.isSelected(row));
		this.tableButtonResult.emit({ action: type, rows: selectedRows });
	}


	// 判断是否全选
	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected >= numRows;
	}

	// 全选/全不选
	masterToggle(): void {
		this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
	}

	/** The label for the checkbox on the passed row */
	checkboxLabel(row?: any): string {
		if (!row) {
			return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
		}
		return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
	}

	// 排序
	sort(event: PageEvent): void {
		this.sortResult.emit(event);
	}

	// 分页
	page(event: PageEvent): void {
		this.pageResult.emit(event);
	}

	// 操作
	canShowAction(type: ActionType): boolean {
		if (!this.actions || this.actions.length === 0) {
			return false;
		} else {
			return this.actions.indexOf(type) !== -1;
		}
	}

	view(row: any): void {
		this.viewResult.emit(row);
	}

	edit(row: any): void {
		this.editResult.emit(row);
	}

	delete(row: any): void {
		this.deleteResult.emit(row);
	}

	disabled(row: any): void {
		this.disabledResult.emit(row);
	}

	// 计划性检查
	start(row: any): void {
		this.startResult.emit(row);
	}

	end(row: any): void {
		this.endResult.emit(row);
	}

	pause(row: any): void {
		this.pauseResult.emit(row);
	}

	stop(row: any): void {
		this.stopResult.emit(row);
	}

	viewChild(row: any): void {
		this.viewChildResult.emit(row);
		if (row.task_type == '_PSFO_TASK_TYPE_CONDUCT_ACTION') {
			this.router.navigate(['rectification-task'], { queryParams: { id: row.id, view: 'view' } });
		} else if (row.task_type == '_PSFO_TASK_TYPE_REVIEW_INJECT') {
			this.router.navigate(['rectification-task'], { queryParams: { id: row.id, view: 'view' } });
		} else if (row.task_type == '_PSFO_TASK_TYPE_VERIFY_ACTION') {
			this.router.navigate(['rectification-task/verify-task'], { queryParams: { id: row.id, view: 'view'	} });
		} else if (row.task_type == '_PSFO_TASK_TYPE_CLOSE_PERMIT') {
			this.router.navigate(['rectification-task/review-task'], { queryParams: { id: row.inspectionId, source: row.id, title: 'check_close_audit', view: 'view'	} });
		}
	}
}
