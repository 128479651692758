// Modules
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
// Services
import { MessageService } from 'src/app/shared/services/message.service';
import { UserManagementService } from 'src/app/shared/services/user-management.service';
// Structs
import { StatusCode } from 'src/app/shared/structs/status-code.struct';
import { BasicConfig } from 'src/app/shared/basic-config';
import { SnackBarType } from 'src/app/shared/structs/snack-bar-type.struct';

@Component({
	selector: 'change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
	passwordFormatRegExp: RegExp = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}/;

	passwordForm: FormGroup = this.formBuilder.group({
		currentPassword: new FormControl(null, Validators.required),
		newPassword: new FormControl(null, Validators.compose([Validators.required, Validators.pattern(this.passwordFormatRegExp)])),
		newPasswordConfirm: new FormControl(null, Validators.compose([Validators.required, Validators.pattern(this.passwordFormatRegExp)]))
	});

	constructor(
		private router: Router,
		private formBuilder: FormBuilder,
		private dialogRef: MatDialogRef<ChangePasswordComponent>,
		private messageService: MessageService,
		private userManagementService: UserManagementService
		) { }

	ngOnInit(): void {
		this.passwordForm.setValidators([this.newPasswordMatch, this.newPasswordSame]);
	}

	// Custom Validation Function for checking password match
	newPasswordMatch: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
		let password: string = group.get('newPassword')!.value;
		let passwordConfirm: string = group.get('newPasswordConfirm')!.value;
		return password === passwordConfirm ? null : { noMatch: true };
	}

	// Custom Validation Function for checking password duplicate
	newPasswordSame: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
		let oldPassword: string = group.get('currentPassword')!.value;
		let newPassword: string = group.get('newPassword')!.value;
		return oldPassword !== newPassword ? null : { sameAfterChange: true };
	}

	validationPassed(ngForm: NgForm): boolean {
		if (ngForm.form.invalid) {
			if (ngForm.form.controls.currentPassword.errors) {
				switch (Object.keys(ngForm.form.controls.currentPassword.errors)[0]) {
					case 'required':
					this.messageService.openSnackBar('ERROR.REQUIRED', SnackBarType.ERROR);
					break;
				}
			} else if (ngForm.form.controls.newPassword.errors) {
				switch (Object.keys(ngForm.form.controls.newPassword.errors)[0]) {
					case 'required':
					this.messageService.openSnackBar('ERROR.REQUIRED', SnackBarType.ERROR);
					break;

					case 'pattern':
					this.messageService.openSnackBar('ERROR.PASSWORD_PATTERN', SnackBarType.ERROR, null, 10000);
					break;
				}
			} else if (ngForm.form.controls.newPasswordConfirm.errors) {
				switch (Object.keys(ngForm.form.controls.newPasswordConfirm.errors)[0]) {
					case 'required':
					this.messageService.openSnackBar('ERROR.REQUIRED', SnackBarType.ERROR);
					break;

					case 'pattern':
					this.messageService.openSnackBar('ERROR.PASSWORD_PATTERN', SnackBarType.ERROR, null, 10000);
					break;
				}
			} else if (ngForm.form.errors) {
				switch (Object.keys(ngForm.form.errors)[0]) {
					case 'sameAfterChange':
					this.messageService.openSnackBar('ERROR.PASSWORD_SAME', SnackBarType.ERROR);
					break;

					case 'noMatch':
					this.messageService.openSnackBar('ERROR.PASSWORD_NOT_MATCH', SnackBarType.ERROR);
					break;
				}
			}
			return false;
		} else {
			return true;
		}
	}

	async submit(ngForm: NgForm): Promise<any> {
		if (this.validationPassed(ngForm)) {
			const that: this = this;
			const promise = new Promise((resolve, reject) => {
				const passwordData: any = {
					email: localStorage.getItem('email') ?? '',
					oldPassword: ngForm.form.controls.currentPassword.value,
					newPassword: ngForm.form.controls.newPassword.value
				};
				that.userManagementService.changePassword(passwordData).subscribe(res => {
					if (res && res.code === StatusCode.OK) {
						that.messageService.openSnackBar('SYSTEM.UPDATE_SUCCESS');
						setTimeout(() => {
							that.router.navigate(['login']);
							that.dialogRef.close();
						}, BasicConfig.duration);
						resolve(null);
					} else {
						that.messageService.openSnackBar(res.msg ?? 'ERROR.UNKNOWN_ERROR', SnackBarType.ERROR);
						reject();
					}
				}, err => {
					that.messageService.openSnackBar('ERROR.UNKNOWN_ERROR', SnackBarType.ERROR);
					reject();
				});
			});
			return promise;
		}
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
