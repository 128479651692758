<div class="header">
	<img class="img" src="assets/logo/qcs-logo.png" />
	<div class="user-info">
		<div style="position: relative;">
			<mat-icon (click)="matMenuClick()" aria-hidden="false" style="margin-right: 10px;"
				[matMenuTriggerFor]="task" class="task">view_list
			</mat-icon>
			<span (click)="matMenuClick()" [matMenuTriggerFor]="task" class="badge badge-danger">
				{{ tasks.length }} </span>
		</div>
		<mat-menu #task="matMenu" class="taskListClass">
			<div class="topTitleMenu">
				<label>{{ 'header.task' | translate:{tasks: tasks.length, pending: pendingTasksNumber} }}</label>
				<label class="view-all" (click)="viewAll()"
					style="margin-left: 25px; color: #578EBE; cursor: pointer;">{{ 'LABEL_VIEW_ALL' | translate }}</label>
			</div>
			<div class="header-task">
				<div class="task-list">
					<div class="task" *ngFor="let task of tasks">
						<div (click)="viewMore(task)" style="cursor: pointer;">
							<label style="font-weight: 700;">{{ task.subject }}</label>
							<label>{{ 'LABEL_VIEW_MORE' | translate }}</label>
						</div>
						<div>
							<button style="margin-right: 10px;"
								[hidden]="task.assignee !== null || task.task_type != '_PSFO_TASK_TYPE_REVIEW_INSPECTION'"
								type="button" class="responsive-button btn  btn-primary m-btn--custom"
								(click)="$event.stopPropagation();accept(task)">
								<span>{{ 'LABEL_ACCEPT_TAST' | translate }}</span>
							</button>
							<button style="padding: 0 15px;    width: auto;"
								[hidden]="task.assignee !== null || task.task_type != '_PSFO_TASK_TYPE_REVIEW_INSPECTION'"
								type="button" class="responsive-button btn btn-secondary m-btn--custom"
								(click)="$event.stopPropagation();acceptStart(task)">
								<span>{{ 'LABEL_ACCEPT_START_TASK' | translate }}</span>
							</button>


							<button style="margin-right: 10px;"
								*ngIf="task.lifecycle == '_PSFO_LIFECYCLE_PENDING' && task.task_type != '_PSFO_TASK_TYPE_REVIEW_INSPECTION'"
								type="button" class="responsive-button btn  btn-primary m-btn--custom"
								(click)="$event.stopPropagation();acceptStartPending(task,'accept')">
								<span>{{ 'LABEL_ACCEPT_TAST' | translate }}</span>
							</button>
							<button style=" padding: 0 15px;   width: auto;"
								*ngIf="task.lifecycle == '_PSFO_LIFECYCLE_PENDING' && task.task_type != '_PSFO_TASK_TYPE_REVIEW_INSPECTION'"
								type="button" class="responsive-button btn btn-secondary m-btn--custom"
								(click)="$event.stopPropagation();acceptStartPending(task,'accept_start')">
								<span>{{ 'LABEL_ACCEPT_START_TASK' | translate }}</span>
							</button>

							<button style="margin-right: 10px;"
								*ngIf="task.task_type == '_PSFO_TASK_TYPE_REVIEW_INSPECTION' && (task.lifecycle == '_PSFO_LIFECYCLE_PENDING' ||task.lifecycle == '_PSFO_LIFECYCLE_TODO' ) && task.task_type != '_PSFO_TASK_TYPE_REVIEW_INSPECTION'"
								type="button" class="responsive-button btn btn-primary m-btn--custom"
								(click)="$event.stopPropagation();start(task)">
								<span>{{ 'LABEL_START_TAST' | translate }}</span>
							</button>
							<button
								*ngIf="(task.task_type == '_PSFO_TASK_TYPE_VERIFY_ACTION' || task.task_type == '_PSFO_TASK_TYPE_CLOSE_PERMIT' || task.task_type == '_PSFO_TASK_TYPE_CONDUCT_ACTION' || task.task_type == '_PSFO_TASK_TYPE_REVIEW_INJECT') && (task.lifecycle == '_PSFO_LIFECYCLE_TODO') && task.task_type != '_PSFO_TASK_TYPE_REVIEW_INSPECTION'"
								type="button" class="responsive-button btn btn-primary m-btn--custom"
								(click)="$event.stopPropagation();acceptTask(task);">{{ 'LABEL_START_TAST' | translate }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</mat-menu>
		<div class="divider"></div>
		<div class="person" style="margin-left: 15px;">
			<mat-icon aria-hidden="false" [matMenuTriggerFor]="menu">person</mat-icon>
			<mat-menu #menu="matMenu" class="log-out">
				<button mat-menu-item *ngIf="showZh"
					(click)="changeLanguage()">{{ 'SYSTEM.CHINESE' | translate }}</button>
				<button mat-menu-item *ngIf="!showZh"
					(click)="changeLanguage()">{{ 'SYSTEM.ENGLISH' | translate }}</button>
				<button mat-menu-item (click)="logOut()">{{ 'SYSTEM.LOG_OUT' | translate }}</button>
			</mat-menu>
		</div>
		<div class="user-name" style="color: #999999; margin-left: 25px;">{{ userName }}</div>
	</div>
</div>
<div class="login-hint-header text-center" *ngIf="showBroserSupportHint">
	<div>
		<span>{{ 'BROWSER_SUPPORT' | translate  }}</span>
	</div>
	<div style="margin-left: 40px; cursor: pointer;" (click)="closeHint()">
		×
	</div>
</div>