// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NG_VALIDATORS, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputNumberModule } from 'primeng/inputnumber';
import { MegaMenuModule } from 'primeng/megamenu';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TranslateModule } from '@ngx-translate/core';
// Components
import { HeaderComponent } from 'src/app/shared/components/header/header.component';
import { TableComponent } from 'src/app/shared/components/table/table.component';
import { CounterTextareaComponent } from 'src/app/shared/components/counter-textarea/counter-textarea.component';
import { ExtendTableComponent } from 'src/app/shared/components/extend-table/extend-table.component';
import { QueryFieldsComponent } from 'src/app/shared/components/query-fields/query-fields.component';
import { DeleteConfirmComponent } from 'src/app/shared/components/delete-confirm/delete-confirm.component';
import { ClickConfirmComponent } from 'src/app/shared/components/click-confirm/click-confirm.component';
import { DropdownExtraComponent } from 'src/app/shared/components/dropdown-extra/dropdown-extra.component';
import { MegaMenuComponent } from 'src/app/shared/components/mega-menu/mega-menu.component';
// Pipes
import { HlmlPipe } from 'src/app/shared/pipe/hlml.pipe';
import { TranslateCodePipe } from 'src/app/shared/pipe/translate-code.pipe';

@NgModule({
	declarations: [
	HlmlPipe,
	TranslateCodePipe,
	HeaderComponent,
	TableComponent,
	QueryFieldsComponent,
	DeleteConfirmComponent,
	ClickConfirmComponent,
	ExtendTableComponent,
	CounterTextareaComponent,
	DropdownExtraComponent,
	MegaMenuComponent
	],
	imports: [
	CommonModule,
	TranslateModule.forChild(),
	FormsModule,
	ReactiveFormsModule,
	MatInputModule,
	MatButtonModule,
	MatFormFieldModule,
	MatIconModule,
	MatTableModule,
	MatPaginatorModule,
	MatCheckboxModule,
	MatSelectModule,
	MatRadioModule,
	MatMenuModule,
	MatSortModule,
	MatDialogModule,
	CalendarModule,
	AutoCompleteModule,
	MatSnackBarModule,
	MatProgressSpinnerModule,
	DropdownModule,
	MultiSelectModule,
	InputNumberModule,
	TableModule,
	OverlayPanelModule,
	MegaMenuModule
	],
	exports: [
	FormsModule,
	ReactiveFormsModule,
	MatInputModule,
	MatButtonModule,
	MatFormFieldModule,
	MatIconModule,
	MegaMenuComponent,
	HeaderComponent,
	TableComponent,
	QueryFieldsComponent,
	ExtendTableComponent,
	CounterTextareaComponent,
	DropdownExtraComponent,
	CommonModule,
	MatTableModule,
	MatPaginatorModule,
	MatCheckboxModule,
	MatSelectModule,
	MatSortModule,
	MatRadioModule,
	MatDialogModule,
	CalendarModule,
	AutoCompleteModule,
	MatProgressSpinnerModule,
	DropdownModule,
	MultiSelectModule,
	InputNumberModule,
	TableModule,
	OverlayPanelModule,
	MegaMenuModule,
	TranslateCodePipe,
	HlmlPipe
	],
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: CounterTextareaComponent,
		multi: true
	}, {
		provide: NG_VALIDATORS,
		useExisting: DropdownExtraComponent,
		multi: true
	}]
})
export class SharedModule { }
