<div class="horizontal-flex">
	<div class="logo">
		<img class="helm-logo" src="assets/logo/helm_logo.svg" height="25px" />
	</div>
	<p-megaMenu [model]="megaMenuList"></p-megaMenu>
	<div class="account" (click)="op.toggle($event)">
		<img class="account-img" src="assets/imgs/account.svg" />
		<span class="role-text">{{roles && roles.length ? roles[0].roleDescription : ''}}</span>
		<img class="arrow-down" src="assets/imgs/arrow_down.svg" />
	</div>
	<p-overlayPanel #op [showCloseIcon]="false" [style]="{width: '186.46px'}">
		<ng-template pTemplate>
			<!-- <button mat-menu-item *ngIf="displayChinese" class="overlay-button"
			(click)="changeLanguage()">{{ 'SYSTEM.CHINESE' | translate }}</button>
			<button mat-menu-item *ngIf="!displayChinese" class="overlay-button"
			(click)="changeLanguage()">{{ 'SYSTEM.ENGLISH' | translate }}</button> -->
			<button mat-menu-item class="overlay-button" (click)="changePassword()">{{ 'SYSTEM.CHANGE_PASSWORD' | translate }}</button>
			<button mat-menu-item class="overlay-button" (click)="logOut()">{{ 'SYSTEM.LOG_OUT' | translate }}</button>
		</ng-template>
	</p-overlayPanel>
</div>