// Modules
import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
	name: 'hlml'
})
export class HlmlPipe implements PipeTransform {

	constructor(private sanitizer: DomSanitizer) {
	}
	
	transform(style: any) {
		return this.sanitizer.bypassSecurityTrustHtml(style);
	}

}
