// Modules
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class CanActiveGuardService implements CanActivate {

	constructor(private router: Router) { }

	canActivate(route: ActivatedRouteSnapshot): boolean {		
		// 如果session中没角色信息则跳转到登录页
		if (!localStorage.getItem('roles')) {
			localStorage.clear();
			this.router.navigate(['login']);
			return false;
		}
		return true;
	}
}
