<div class="modal-container">
	<div class="page-title">
		<div class="title">{{ 'SYSTEM.CHANGE_PASSWORD' | translate }}</div>
	</div>

	<form [formGroup]="passwordForm" #ngPasswordForm="ngForm" (ngSubmit)="submit(ngPasswordForm)">
		<div class="field-section">
			<div class="field-row">
				<!-- Current Password -->
				<div class="field-display" [style.width.%]="100">
					<div class="field-title required">{{ 'SYSTEM.CURRENT_PASSWORD' | translate }}</div>
					<div class="field-content">
						<input pInputText type="password" name="currentPassword" placeholder="{{ 'SYSTEM.CURRENT_PASSWORD' | translate }}" formControlName="currentPassword" [class.error-border]="ngPasswordForm.form.controls['currentPassword'].errors && (ngPasswordForm.form.controls['currentPassword'].touched || ngPasswordForm.submitted)" />
					</div>
				</div>
			</div>
			<div class="field-row">
				<!-- New Password -->
				<div class="field-display" [style.width.%]="100">
					<div class="field-title required">{{ 'SYSTEM.NEW_PASSWORD' | translate }}</div>
					<div class="field-content">
						<input pInputText type="password" name="currentPassword" placeholder="{{ 'SYSTEM.NEW_PASSWORD' | translate }}" formControlName="newPassword" [class.error-border]="ngPasswordForm.form.controls['newPassword'].errors && (ngPasswordForm.form.controls['newPassword'].touched || ngPasswordForm.submitted)" />
					</div>
				</div>
			</div>
			<div class="field-row">
				<!-- New Password Confirm -->
				<div class="field-display" [style.width.%]="100">
					<div class="field-title required">{{ 'SYSTEM.NEW_PASSWORD_CONFIRM' | translate }}</div>
					<div class="field-content">
						<input pInputText type="password" name="currentPassword" placeholder="{{ 'SYSTEM.NEW_PASSWORD_CONFIRM' | translate }}" formControlName="newPasswordConfirm" [class.error-border]="ngPasswordForm.form.controls['newPasswordConfirm'].errors && (ngPasswordForm.form.controls['newPasswordConfirm'].touched || ngPasswordForm.submitted)" />
						<div class="error-message" [class.invisible]="ngPasswordForm.form.controls['newPassword'].value === ngPasswordForm.form.controls['newPasswordConfirm'].value || !ngPasswordForm.form.controls['newPasswordConfirm'].value">{{ 'ERROR.PASSWORD_NOT_MATCH' | translate}}</div>
					</div>
				</div>
			</div>
		</div>

		<div class="dialog-button-section">
			<button type="submit" mat-button class="responsive-button btn confirm btn-fixed-width m-r-1rem">{{ 'LABEL_SUBMIT' | translate }}</button>
			<button type="button" mat-button class="responsive-button btn cancel btn-fixed-width m-r-1rem" (click)="cancel()">{{ 'LABEL_CANCEL' | translate }}</button>
		</div>
	</form>
</div>
