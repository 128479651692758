export const BasicConfig = {
	timeout: 120000, // http请求时间设置
	noTokenUrl: [ // 无需token白名单
	'Token/auth'
	],
	noMenuComponent: [ // 无需显示顶部菜单的页面
	'login',
	'select-fund'
	],
	duration: 3000, // 提示信息持续时间
	spinnerDelay: 200, // spinner延迟时长
	dateFormat: 'MM-dd-yyyy', // 文本显示日期格式
	calendarDateFormat: 'mm-dd-yy', // 组件选择日期格式
	dateTimeFormat: 'MM-dd-yyyy HH:mm:ss', // 文本显示日期时间格式
	allowedExternal: [ // 允许的外部来源url白名单
	'localhost',
	'helm-dev.shlogic.com.cn'
	],
	externalValidationKey: 'U7&%qXGESfpP46w@5vEf&$iimPzfg$tW',
	tablePageReportRightPosition: '20%'
};
